<template id="temp">
  <div>
    <div v-show="isTypeError" style="text-align: center;justify-content: center;margin: auto;padding-top: 100px">
      <div>参数错误</div>
      <div>类型={{type}}</div>
      <div>id={{id}}</div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    let type = this.$route.query.type
    let id = this.$route.query.id
    if (id && type){
      let query  = encodeURIComponent('id='+id+'&gzhType=gzh'+'&businesssource='+this.businessSourceGetType(type))
      location.href = 'weixin://dl/business/?appid=wxb4e1f5b5aa31c06c' + '' +
        '&path=' +this.businessSourceGetPath(type) +
        '&query='+query

      this.isTypeError = false
    }else {
      console.log('类型 =',type)
      console.log('id =',id)
      this.isTypeError = true
    }
  },
  data() {
    return{
      isTypeError:false,
      type:'',
      id:'',
    }
  },
  methods: {
    /**
     * 根据业务类型获取对应小程序的页面路径
     */
    businessSourceGetPath(type){
      if(type === 'ot'){
        return 'threePages/pages/integral/addMyself/addMyself' //任务详情
      }
      if (type === 'integral_supplier'){ //资产
        return 'threePages/pages/integral/addSupplier/addSupplier'
      }
      if (type === 'integral_personnel'){ //人事
        return 'threePages/pages/integral/addPersonnel/addPersonnel'
      }
      if (type === 'cont_manage'){//合同
        return 'threePages/pages/contmanage/addContract/addContract'
      }
      if (type === 'case_project'){ //项目管理
        return 'caseModel/pages/viewCaseproject/viewCaseproject'
      }
      if (type === 'case7'){//PCT
        return 'caseModel/pages/addPct/addPct'
      }
      if (type === 'case5'){//专利无效
        return 'caseModel/pages/addZlwx/addZlwx'
      }
      if (type === 'case3'){//法律顾问
        return 'caseModel/pages/addFlgw/addFlgw'
      }
      if (type === 'case2'){// 刑事案件
        return 'caseModel/pages/addXsaj/addXsaj'
      }
      if (type === 'case1'){// 民商
        return 'caseModel/pages/addMsxz/addMsxz'
      }
      //
      if (type === 'case0'){//原批量案件
        return 'caseModel/pages/addCaseBatch/addCaseBatch'
      }
      if (type === 'case10'){ //批量知产
        return 'caseModel/pages/viewCasebatchproperty/viewCasebatchproperty'
      }
      return 'pages/index/index'
    },

    /**
     * 根据短信type 转换业务类型
     * 其他任务：ot
     * 开庭任务case+案件casetype数字
     */
    businessSourceGetType(type){
      if(type === 'ot'){
        return 'task_myself' //任务详情
      }
      if (type === 'integral_supplier'){ //资产
        return 'integral_supplier'
      }
      if (type === 'integral_personnel'){ //人事
        return 'integral_personnel'
      }
      if (type === 'cont_manage'){//合同
        return 'cont_manage'
      }
      if (type === 'case_project'){ //项目管理
        return 'case_project'
      }
      if (type === 'case7'){//PCT
        return 'case_foreign_pct'
      }
      if (type === 'case5'){//专利无效
        return 'case_patent_invalid'
      }
      if (type === 'case3'){//法律顾问
        return 'case_legal_counsel'
      }
      if (type === 'case2'){// 刑事案件
        return 'case_criminal'
      }
      if (type === 'case1'){// 民商
        return 'case_civil_business'
      }
      if (type === 'case0'){//原批量案件
        return 'case_batch'
      }
      if (type === 'case10'){ //批量知产
        return 'case_batch_property'
      }
      return 'case_batch'
    },
  }
}
</script>
