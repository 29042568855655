<template>
  <div class="homecontainer _homecontainer">
    <div id="htk1" class="main-top-banner">
      <div class="center">
        <div class="lvhuadai">
          <p class="p1">律化带合同库</p>
          <p class="p2">律师事务所数字化方案解决机构</p>
          <!-- <div> -->
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/')">进入律化带合同库</el-button>
          <!-- </div> -->
        </div>
        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp" ref="htk1">
          <img src="/img/hetongku/htk1-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="hetongku">
      <div class="center">
        <div class="left">
          <p>
            <span>1</span><span> /3</span>
          </p>
          <div class="title">
            <div class="contract">
              <span style="color:#397ff9;">合同</span>
              <div class="corner top-left"></div>
              <div class="corner top-right"></div>
              <div class="corner bottom-left"></div>
              <div class="corner bottom-right"></div>
            </div>
            <span style="margin-left: 8px;">库</span>
          </div>
          <div class="info">
            <p>合同库由入驻律师分享与专业法律团队上传合同范本，覆盖各个专业领域，聚焦社会热点信息，实时更新各类协议，将不同领域的合同信息进行分类、归档，助力用户高效检索，付费使用，让您省时、省心更省力！</p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/contractLibrary')">立即体验</el-button>
        </div>
        <!-- 移动端 -->
        <div class="_left">
          <div class="title">
            <p>
              <span>1</span><span> /3</span>
            </p>
            <div class="contract">
              <span style="color:#397ff9;">合同</span><span>库</span>
            </div>
          </div>
          <div class="info">
            <p>合同库由入驻律师分享与专业法律团队上传合同范本，覆盖各个专业领域，聚焦社会热点信息，实时更新各类协议，将不同领域的合同信息进行分类、归档，助力用户高效检索，付费使用，让您省时、省心更省力！</p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/contractLibrary')">立即体验</el-button>
        </div>

        <div :data-wow-offset="isMobile ? 100 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp">
          <img src="/img/hetongku/htk2-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="wenshuku">
      <div class="center">
        <div data-wow-offset="400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp">
          <img src="/img/hetongku/htk3-1.png" alt="">
        </div>
        <div class="right">
          <p>
            <span style="font-size: 36px;">2</span><span> /3</span>
          </p>
          <div class="title">
            <div class="contract">
              <span style="color:#397ff9;">文书</span>
              <div class="corner top-left"></div>
              <div class="corner top-right"></div>
              <div class="corner bottom-left"></div>
              <div class="corner bottom-right"></div>
            </div>
            <span style="margin-left: 8px;">库</span>
          </div>
          <div class="info">

            <p>
              由入驻律师与专业团队上传大量优秀的法律文书模版，为您提供海量标准化的范本。我们的宗旨是让您“看得明白，写得清楚，用得方便，买得轻松”。分类浏览，便捷操作，快速索引定位，您可以在线pdf预览，word文档付费下载，提升办案效率，是各领域法律人的实用文书工具箱。
            </p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/documentLibrary')">立即体验</el-button>
        </div>

      </div>
      <!-- 移动端 -->
      <div class="_center">
        <div class="right">
          <div class="title">
            <p>
              <span>2</span><span> /3</span>
            </p>
            <div class="contract">
              <span style="color:#397ff9;">文书</span>
            </div>
            <span>库</span>
          </div>
          <div class="info">
            <p>
              由入驻律师与专业团队上传大量优秀的法律文书模版，为您提供海量标准化的范本。我们的宗旨是让您“看得明白，写得清楚，用得方便，买得轻松”。分类浏览，便捷操作，快速索引定位，您可以在线pdf预览，word文档付费下载，提升办案效率，是各领域法律人的实用文书工具箱。
            </p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/documentLibrary')">立即体验</el-button>
        </div>

        <div :data-wow-offset="isMobile ? 0 : 400" data-wow-duration="2s" data-wow-delay="0.1s" class="wow img animate__animated animate__fadeInUp">
          <img src="/img/hetongku/htk3-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="zhaolvshi">
      <div class="center">
        <div class="left">
          <p>
            <span>3</span><span> /3</span>
          </p>
          <div class="title">
            <div class="contract">
              <span style="color:#397ff9;">找</span>
              <div class="corner top-left"></div>
              <div class="corner top-right"></div>
              <div class="corner bottom-left"></div>
              <div class="corner bottom-right"></div>
            </div>
            <span style="margin-left: 8px;">律师</span>
          </div>

          <div class="info">
            <p>填写相关认证资料即可快速入驻，免费获得网站个人宣传页面。
              平台为律师量身打造专属个人详情页，用来展示律师个人风采、代表案例，关联其上传的合同与文书模版，建立其个人商城，全方位展示精英律师风采，提高知名度，拓宽案源渠道。网站同时提供跳转链接，同步引流用户上传的合同范本，增加收益。
            </p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/seekLawyer')">立即体验</el-button>
        </div>
        <!-- 移动端 -->
        <div class="_left">
          <div class="title">
            <p>
              <span>3</span><span> /3</span>
            </p>
            <div class="contract">
              <span style="color:#397ff9;">找</span><span>律师</span>
            </div>
          </div>

          <div class="info">
            <p>填写相关认证资料即可快速入驻，免费获得网站个人宣传页面。
              平台为律师量身打造专属个人详情页，用来展示律师个人风采、代表案例，关联其上传的合同与文书模版，建立其个人商城，全方位展示精英律师风采，提高知名度，拓宽案源渠道。网站同时提供跳转链接，同步引流用户上传的合同范本，增加收益。
            </p>
          </div>
          <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/seekLawyer')">立即体验</el-button>
        </div>

        <div :data-wow-offset="isMobile ? 0 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp">
          <img src="/img/hetongku/htk4-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="hezuomoshi">
      <h1>律师合作模式</h1>
      <div class="center">
        <div class="hezuo-card">
          <div class="card">
            <img src="/img/hetongku/htk5-1-1.png" alt="">
            <h3>免费入驻</h3>
            <p>律师注册登录后，在个人中心进行律师认证，填写执业信息及简历，审核通过后即为入驻成功，免费获得个人主页展示。</p>
          </div>
          <div class="card">
            <img src="/img/hetongku/htk5-2-1.png" alt="">
            <h3>分享模板</h3>
            <p>高律师可以自行定价，上传合同、文书模板，享有用户下载带来的高比例收益分成。</p>
          </div>
          <div class="card card3">
            <img src="/img/hetongku/htk5-3-1.png" alt="">
            <h3>文集展示</h3>
            <p>收录专业律师提供的法规解读以及对案件的独到观点、法律论文、法律常识等原创文章。</p>
          </div>
          <div class="card card4">
            <img src="/img/hetongku/htk5-4-1.png" alt="">
            <h3>个性化营销</h3>
            <p>高性价比营销方案，结合律师个人优势打造专业化“品牌律师”，凸显律师个人所长，落实优质内容转化，全面赋能律师。</p>
          </div>
        </div>

        <!-- 移动端 -->
        <div class="_hezuo-card">
          <div class="card card1">
            <img src="/img/hetongku/htk5-1-1.png" alt="">
            <h3>免费入驻</h3>
            <p>律师注册登录后，在个人中心进行律师认证，填写执业信息及简历，审核通过后即为入驻成功，免费获得个人主页展示。</p>
          </div>
          <div class="card">
            <img src="/img/hetongku/htk5-2-1.png" alt="">
            <h3>分享模板</h3>
            <p>高律师可以自行定价，上传合同、文书模板，享有用户下载带来的高比例收益分成。</p>
          </div>
        </div>
        <div class="_hezuo-card" style="margin-bottom: 6vw;">
          <div class="card card3">
            <img src="/img/hetongku/htk5-3-1.png" alt="">
            <h3>文集展示</h3>
            <p>收录专业律师提供的法规解读以及对案件的独到观点、法律论文、法律常识等原创文章。</p>
          </div>
          <div class="card card4">
            <img src="/img/hetongku/htk5-4-1.png" alt="">
            <h3>个性化营销</h3>
            <p>高性价比营销方案，结合律师个人优势打造专业化“品牌律师”，凸显律师个人所长，落实优质内容转化，全面赋能律师。</p>
          </div>
        </div>
      </div>

    </div>

    <div class="youshi">
      <div class="title">我们的优势</div>
      <div class="youshi-card">
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-1.png" alt="">
            <span>发展速度快</span>
          </div>
          <div class="card-info">
            <p>“平台在中，两头在外”。律化带合同库平台上提供的合同、文书等内容并非全部由平台自我生产，更多依赖平台入驻律师提供，平台只需要进行筛选和分类，此番优势在于发展速度快，可以实现裂变。</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-2.png" alt="">
            <span>种类齐全、内容专业</span>
          </div>
          <div class="card-info">
            <p>平台分享各种法律业务方向的合同、文书，有空白范本、部分也有带数据（高仿真）范本。平台上的合同等内容均出自律师或者相关专业有经验者，平台也会加以审核，质量有保证。</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-3.png" alt="">
            <span>下载、分享便捷</span>
          </div>
          <div class="card-info">
            <p>合同、文书通过搜索关键字下载可选择word格式。无论PC端、小程序（开发中）还是公众号，均可分享至朋友圈，传播迅速方便。</p>
          </div>
        </div>
      </div>
      <div class="youshi-card">
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-4.png" alt="">
            <span>收益分成</span>
          </div>
          <div class="card-info">
            <p>免费入驻。律师可以自主上传合同、文书范本，对其上传的合同、文书范本自行定价，可获得下载收益分成。</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-5.png" alt="">
            <span>我们团队</span>
          </div>
          <div class="card-info">
            <p>不仅有一群热爱合同的法律团队，通过挑选、修改、优化、调整，打磨每份合同，还有专业的技术人员保障用户体验，共同致力于为用户提供质量过关、专业权威、使用率高的合同。</p>
          </div>
        </div>
        <div class="card" style="visibility: hidden;">
        </div>
      </div>

      <!-- 移动端 -->
      <div class="_youshi-card">
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-1.png" alt="">
            <span>发展速度快</span>
          </div>
          <div class="card-info">
            <p>“平台在中，两头在外”。律化带合同库平台上提供的合同、文书等内容并非全部由平台自我生产，更多依赖平台入驻律师提供，平台只需要进行筛选和分类，此番优势在于发展速度快，可以实现裂变。</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-2.png" alt="">
            <span>种类齐全、内容专业</span>
          </div>
          <div class="card-info">
            <p>平台分享各种法律业务方向的合同、文书，有空白范本、部分也有带数据（高仿真）范本。平台上的合同等内容均出自律师或者相关专业有经验者，平台也会加以审核，质量有保证。</p>
          </div>
        </div>
      </div>

      <div class="_youshi-card">
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-3.png" alt="">
            <span>下载、分享便捷</span>
          </div>
          <div class="card-info">
            <p>合同、文书通过搜索关键字下载可选择word格式。无论PC端、小程序（开发中）还是公众号，均可分享至朋友圈，传播迅速方便。</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-4.png" alt="">
            <span>收益分成</span>
          </div>
          <div class="card-info">
            <p>免费入驻。律师可以自主上传合同、文书范本，对其上传的合同、文书范本自行定价，可获得下载收益分成。</p>
          </div>
        </div>
      </div>
      <div class="_youshi-card" style="margin-bottom: 10vw;">
        <div class="card">
          <div class="card-title">
            <img src="/img/hetongku/htk6-5.png" alt="">
            <span>我们团队</span>
          </div>
          <div class="card-info">
            <p>不仅有一群热爱合同的法律团队，通过挑选、修改、优化、调整，打磨每份合同，还有专业的技术人员保障用户体验，共同致力于为用户提供质量过关、专业权威、使用率高的合同。</p>
          </div>
        </div>
        <div class="card" style="visibility: hidden;">
        </div>
      </div>
    </div>



    <div class="shiyong">
      <h1>律化带合同库 诚邀律师入驻</h1>
      <div class="ruzhu">
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-1.png" alt="">
          <span>免费入驻 | 主页展示</span>
        </div>
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-2.png" alt="">
          <span>提供模板 | 赚取收益</span>
        </div>
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-3.png" alt="">
          <span>多维内容 | 赋能律师</span>
        </div>
      </div>
      <div class="_ruzhu">
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-1.png" alt="">
          <span>免费入驻</span> <span>主页展示</span>
        </div>
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-2.png" alt="">
          <span>提供模板</span> <span>赚取收益</span>
        </div>
        <div class="ruzhu-item">
          <img src="/img/hetongku/htk7-3.png" alt="">
          <span>多维内容</span><span>赋能律师</span>
        </div>
      </div>
      <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/')">立即入驻</el-button>
    </div>


  </div>
</template>

<script>
export default {
  name: '',
  components: {
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    if (window.innerWidth < 800) {
      this.isMobile = true
    }
  },
  methods: {
    goTo(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="less" scoped>
// 大于1200px
@media only screen and (min-width: 1200px) {
  .homecontainer {
    font-size: 1vw;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .main-top-banner {
      .center {
        width: 1200px;
        // background-color: #256ed4;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      height: 520px;
      background-image: url("../../../public/img/hetongku/htk1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .lvhuadai {
        width: 600px;
        // position: absolute;
        // transform: translate(50%, 50%);
        text-align: left;
        top: 100px;

        p {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .p1 {
          font-size: 40px;
          font-weight: 600;
          color: #242526;
          margin-bottom: 20px;
        }

        .p2 {
          font-size: 26px;
          color: #65666D;
        }

        .button {
          margin-top: 30px;
          font-size: 16px;
          color: #fff;
          height: 46px;
          width: 200px;
          border-radius: 8px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 654px;
        transform: translateY(10%);

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .hetongku {
      height: 520px;
      background-image: url("../../../public/img/hetongku/htk2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      ._left {
        display: none;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 500px;
        height: 100%;

        p {
          :nth-child(1) {
            font-size: 36px;
          }

          :nth-child(2) {
            font-size: 20px;
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        span {
          position: relative;
          font-size: 32px;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 80px;
          height: 40px;
          border: 1px dashed #397ff9;


          .corner {
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: #397ff9;
          }

          .top-left {
            top: -2px;
            left: -2px;
          }

          .top-right {
            top: -2px;
            right: -2px;
          }

          .bottom-left {
            bottom: -2px;
            left: -2px;
          }

          .bottom-right {
            bottom: -2px;
            right: -2px;
          }
        }

        .info {
          width: 400px;
          margin-top: 20px;

          p {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            color: #818181;
          }
        }

        .button {
          font-size: 16px;
          color: #fff;
          height: 40px;
          width: 170px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 700px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .wenshuku {
      height: 600px;
      background-image: url("../../../public/img/hetongku/htk3.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ._center {
        display: none;
      }

      .center {
        width: 1200px;
        // background-color: #c2d9fa;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // background-color: #ebebeb;
        width: 460px;
        height: 100%;

        p {
          :nth-child(1) {
            font-size: 36px;
          }

          :nth-child(2) {
            font-size: 20px;
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        span {
          position: relative;
          font-size: 32px;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 80px;
          height: 40px;
          border: 1px dashed #397ff9;


          .corner {
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: #397ff9;
          }

          .top-left {
            top: -2px;
            left: -2px;
          }

          .top-right {
            top: -2px;
            right: -2px;
          }

          .bottom-left {
            bottom: -2px;
            left: -2px;
          }

          .bottom-right {
            bottom: -2px;
            right: -2px;
          }
        }

        .info {
          width: 420px;
          margin-top: 20px;

          p {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            color: #818181;
          }
        }

        .button {
          font-size: 16px;
          color: #fff;
          height: 40px;
          width: 170px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {

        // width: 600px;
        // transform: translateY(10%);
        img {
          width: 700px;
          // width: 100%;
          object-fit: cover;
        }
      }
    }

    .zhaolvshi {
      height: 520px;
      background-image: url("../../../public/img/hetongku/htk2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ._left{
        display: none;
      }
      .center {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 500px;
        height: 100%;

        p {
          :nth-child(1) {
            font-size: 36px;
          }

          :nth-child(2) {
            font-size: 20px;
          }
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        span {
          position: relative;
          font-size: 32px;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 40px;
          height: 40px;
          border: 1px dashed #397ff9;


          .corner {
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: #397ff9;
          }

          .top-left {
            top: -2px;
            left: -2px;
          }

          .top-right {
            top: -2px;
            right: -2px;
          }

          .bottom-left {
            bottom: -2px;
            left: -2px;
          }

          .bottom-right {
            bottom: -2px;
            right: -2px;
          }
        }

        .info {
          width: 400px;
          margin-top: 20px;

          p {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            color: #818181;
          }
        }

        .button {
          font-size: 16px;
          color: #fff;
          height: 40px;
          width: 170px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 700px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .hezuomoshi {
      height: 600px;
      background-image: url("../../../public/img/hetongku/htk5.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      h1 {
        font-size: 34px;
        color: #fff;
        font-weight: 500;
      }

      .center {
        width: 1200px;
        display: flex;

        justify-content: space-between;
        align-items: center;
        ._hezuo-card {
          display: none;
        }
        .hezuo-card {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .card {
            width: 260px;
            height: 327px;
            background-image: url("../../../public/img/hetongku/htk5-1.png");
            background-repeat: no-repeat;
            background-position: center;
            box-sizing: border-box;
            background-size: cover;
            border-radius: 4px;
            color: #fff;
            padding: 20px;

            h3 {
              margin: 16px 0;
              font-size: 22px;
              font-weight: 500;
            }
            p {
              text-align: left;
              line-height: 30px;
              font-size: 14px;
            }
          }
          .card3 {
            background-image: url("../../../public/img/hetongku/htk5-3.png");
          }          .card4 {
            background-image: url("../../../public/img/hetongku/htk5-4.png");
          }
          .card:hover {
            scale: 1.05;
            transition: 0.3s;
          }
        }
      }
    }

    .youshi {
      height: 1000px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;

      .title {
        height: 60px;
        line-height: 60px;
        font-size: 34px;
        margin: 40px 0;
      }
      ._youshi-card{
        display: none;
      }

      .youshi-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 400px;
        width: 1200px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .card {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          height: 350px;
          width: 360px;
          font-size: 16px;
          padding: 30px;
          box-sizing: border-box;
          box-shadow: 0px 3px 15px rgba(78, 115, 193, 0.16);

          .card-title {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            height: 160px;
            font-weight: 500;

            span {
              color: #444444;
              font-size: 20px;
              font-weight: 600;
              margin: 10px 0;
            }
          }

          .card-info {
            text-align: left;
            line-height: 30px;
            font-size: 14px;
            height: 100%;

            p {
              margin: 10px;
              font-size: 14px;
              color: #7B7B7B;
            }
          }

          img {
            width: 90px;
            height: 90px;

          }
        }
      }
    }


    .shiyong {
      background-image: url("../../../public/img/hetongku/htk7.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 300px;
      background-color: #256ed4;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;

      h1 {
        font-size: 34px;
        margin-bottom: 40px;
        font-weight: 500;
      }
      ._ruzhu {
        display: none;
      }
      .ruzhu {
        width: 900px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ruzhu-item {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
        }
      }

      .button {
        margin-top: 40px;
        width: 158px;
        height: 50px;
        font-size: 20px;
        color: #3564FE;
        border-radius: 0;

      }
    }
  }
}

// // 小于1200px
@media only screen and (max-width: 1200px) {
  .homecontainer {
    font-size: 1vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;

    .main-top-banner {
      .center {
        // background-color: #256ed4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }

      background-image: url("../../../public/img/hetongku/htk1-m.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .lvhuadai {
        text-align: center;
        padding-top: 20vw;

        p {
          font-size: 16px;
          margin-bottom: 1vw;
        }

        .p1 {
          font-size: 6vw;
          font-weight: 600;
          color: #242526;
          // margin-bottom: 2vw;
        }

        .p2 {
          font-size: 4vw;
          color: #7F90A7;
        }

        .button {
          margin: 2vw;
          font-size: 14px;
          color: #fff;
          height: 40px;
          width: 160px;
          border-radius: 4px;
          letter-spacing: 1.1px;
          text-align: center;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 80vw;
        margin-bottom: 4vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .hetongku {
      // height: 520px;
      background-image: url("../../../public/img/hetongku/htk2.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        // width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }

      .left {
        display: none;
      }

      ._left {
        display: flex;
        margin-top: 6vw;
        flex-direction: column;
        padding: 0 4vw;
        align-items: center;

        p {
          :nth-child(1) {
            font-size: 6vw;
            position: relative;
            left: 2vw;
          }

          :nth-child(2) {
            font-size: 5vw;
          }
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: flex-end;

        }

        span {
          position: relative;
          font-size: 6vw;
          letter-spacing: 1vw;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .info {
          margin-top: 2vw;
          padding: 0 4vw;

          p {
            text-align: center;
            font-size: 3vw;
            line-height: 6vw;
            color: #818181;
          }
        }

        .button {
          font-size: 14px;
          color: #fff;
          height: 40px;
          width: 160px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 80vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .wenshuku {
      background-image: url("../../../public/img/hetongku/htk3.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: none;
      }

      ._center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }

      .right {
        display: flex;
        margin-top: 6vw;
        padding: 0 4vw;
        flex-direction: column;
        align-items: center;

        p {
          :nth-child(1) {
            font-size: 6vw;
            position: relative;
            left: 2vw;
          }

          :nth-child(2) {
            font-size: 5vw;
          }
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: flex-end;

        }

        span {
          position: relative;
          font-size: 6vw;
          letter-spacing: 1vw;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .info {
          margin-top: 2vw;
          padding: 0 4vw;

          p {
            text-align: center;
            font-size: 3vw;
            line-height: 6vw;
            color: #818181;
          }
        }

        .button {
          font-size: 14px;
          color: #fff;
          height: 40px;
          width: 160px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 80vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .zhaolvshi {
      background-image: url("../../../public/img/hetongku/htk2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }

      .left {
        display: none;
      }

      ._left {
        display: flex;
        margin-top: 6vw;
        padding: 0 4vw;
        flex-direction: column;
        align-items: center;

        p {
          :nth-child(1) {
            font-size: 6vw;
            position: relative;
            left: 2vw;
          }

          :nth-child(2) {
            font-size: 5vw;
          }
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: flex-end;

        }

        span {
          position: relative;
          font-size: 6vw;
          letter-spacing: 1vw;
          font-weight: 600;
          top: -2px;
        }

        .contract {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .info {
          margin-top: 2vw;
          padding: 0 4vw;

          p {
            text-align: center;
            font-size: 3vw;
            line-height: 6vw;
            color: #818181;
          }
        }

        .button {
          font-size: 14px;
          color: #fff;
          height: 40px;
          width: 160px;
          border-radius: 20px;
          margin-top: 20px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        width: 80vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .hezuomoshi {
      background-image: url("../../../public/img/hetongku/htk5.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      h1 {
        font-size: 6vw;
        margin: 6vw 0 4vw 0;
        color: #fff;
        font-weight: 500;
      }

      .center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .hezuo-card {
          display: none;
        }

        ._hezuo-card {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .card {
            width: 43vw;
            height: 64vw;
            background-image: url("../../../public/img/hetongku/htk5-1.png");
            background-repeat: no-repeat;
            box-sizing: border-box;
            background-position: center;
            background-size: cover;
            border-radius: 4px;
            color: #fff;
            padding: 2vw;
            margin-bottom: 4vw;

            img {
              width: 16vw;
            }

            h3 {
              margin: 2vw 0;
              font-size: 4vw;
              font-weight: 500;
            }

            p {
              text-align: left;
              line-height: 6vw;
              font-size: 3vw;
            }
          }

          .card1 {
            margin-right: 3vw;
          }

          .card3 {
            margin-right: 3vw;
            background-image: url("../../../public/img/hetongku/htk5-3.png");
          }

          .card4 {
            background-image: url("../../../public/img/hetongku/htk5-4.png");
          }

          .card:hover {
            scale: 1.05;
            transition: 0.3s;
          }
        }
      }
    }

    .youshi {
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      flex-direction: column;

      .title {
        height: 6vw;
        line-height: 7vw;
        font-size: 6vw;
        margin: 10vw 0 4vw 0;
      }

      .youshi-card {
        display: none;
      }
      ._youshi-card {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .card {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          height: 64vw;
          width: 43vw;
          box-sizing: border-box;
          margin-right: 3vw;
          margin-bottom: 4vw;
          padding: 2vw 1vw 1vw 1vw;
          box-shadow: 0px 3px 15px rgba(78, 115, 193, 0.16);

          .card-title {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;

            span {
              color: #444444;
              font-size: 3.5vw;
              font-weight: 600;
              margin: 1vw 0;
            }
          }

          .card-info {
            text-align: left;
            font-size: 3vw;

            p {
              padding: 1vw;
              font-size: 3vw;
              line-height: 6vw;
              height: 40vw;
              color: #7B7B7B;
              overflow: auto;
            }
          }

          img {
            width: 12vw
          }
        }
        :nth-child(2) {
            margin-right: 0;
          }
      }
    }


    .shiyong {
      background-image: url("../../../public/img/hetongku/htk7.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      background-color: #256ed4;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;

      h1 {
        font-size: 5.5vw;
        margin:10vw 0 6vw 0;
        font-weight: 500;
      }
      .ruzhu {
        display: none;
      }
      ._ruzhu {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ruzhu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: 10vw;
          span{
            font-size: 3vw;
            margin-bottom: 1vw;
            letter-spacing: 1vw;
          }

          img {
            margin-bottom: 2vw;
            width: 4vw;
          }
        }
        :nth-last-child(1) {
          margin-right: 0;
        }
      }

      .button {
        margin: 4vw 0 6vw 0 ;
        width: 120px;
        height: 34px;
        font-size: 14px;
        padding: 0;
        color: #3564FE;
        border-radius: 0;
      }
    }
  }
}</style>
