<template>
  <div class="homecontainer _homecontainer">
    <div class="top">
      <div class="center">
        <div class="t1">
          <span>关于我们</span> <span>/ ABOUT US</span>
        </div>
        <div class="t2">
          <span>科技支撑</span>
          <span>诚信经营</span>
          <span>合作共赢</span>
        </div>

      </div>

    </div>
    <div class="lvhuadai">
      <div class="center">
        <div class="text">
          <p>浙江律化带科技有限公司</p>
          <div class="line"></div>
          <p>浙江律化带科技有限公司<span style="font-weight: 600;">（国家高新技术企业，杭州市雏鹰计划企业）</span>
            成立于2015年，坐落在美丽的杭州市西湖区，其中IT事业部，致力于为法律行业、知识产权行业、科技服务业类软件系统开发、微信小程序开发、网页开发、APP等开发。</p>
          <div class="about-us"><span>ABOUT US</span></div>
        </div>
        <div :data-wow-offset="isMobile ? 10 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp" ref="htk1">
          <img src="/img/about/About2-1.png" alt="">
        </div>
      </div>
    </div>
    <!-- 律化带价值观 -->
    <div class="jiazhiguan">
      <div class="center">
        <div class="left">
          <div class="t1">
            <h2>律化带价值观 |</h2>
          </div>
          <div class="t2">
            <h3>我们的愿景</h3>
            <p>打造全方位列表式案件进度管理</p>
            <p>移动办公，提高律所技术化与知识化管理能力</p>
          </div>
          <div class="t3">
            <h3>我们的宗旨</h3>
            <p>做99%完美产品 让51%的人满意</p>
          </div>
        </div>
        <div class="right">
          <img src="/img/about/About3-1.png" alt="">
          <img src="/img/about/About3-2.png" alt="">
        </div>
      </div>

      <!-- 移动端 律化带价值观 -->
      <div class="_center">
        <img class="top-img" src="/img/about/About3-m-1.png" alt="">
        <div class="text">
          <div class="t1">
            <h2>律化带价值观 |</h2>
          </div>
          <div class="t2">
            <h3>我们的愿景</h3>
            <p>打造全方位列表式案件进度管理</p>
            <p>移动办公，提高律所技术化与知识化管理能力</p>
          </div>
          <div class="t3">
            <h3>我们的宗旨</h3>
            <p>做99%完美产品 让51%的人满意</p>
          </div>
        </div>

      </div>
    </div>

    <!-- 证书 -->
    <div class="certificate">
      <div class="center">
        <div class="item">
          <div class="item-left">
            <img src="/img/about/About5-1.png" alt="">
            <div>
              <h4>高新技术企业证书</h4>
            </div>
          </div>
          <div class="item-right">
            <img src="/img/about/About5-2.png" alt="">
          </div>
        </div>
        <div class="item">
          <div class="item-right mobile">
            <img src="/img/about/About5-4.png" alt="">
          </div>
          <div class="item-left">
            <img src="/img/about/About5-3.png" alt="">
            <div>
              <h4>杭州市“雏鹰计划”企业证书</h4>
            </div>
          </div>
          <div class="item-right pc">
            <img src="/img/about/About5-4.png" alt="">
          </div>
        </div>
      </div>

    </div>

    <!-- 地址 联系方式 -->
    <div class="info">
      <div class="center">
        <div class="left">
          <div class="text">
            <img src="/img/about/About4-1.png" alt=""> <span>13372576766</span>
          </div>
          <div class="text dizhi">
            <img src="/img/about/About4-3.png" alt="">
            <div>
              <span>浙江省杭州市</span>
              <span>西溪路527号钱江浙商创投中心C座2楼</span>
            </div>
          </div>
          <div class="text code">
            <img src="/img/about/About4-4.png" alt=""> <span>微信扫一扫</span>
          </div>

        </div>
        <div class="right">
          <img src="/img/about/ABOUT4-5.png" alt="">
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    if (window.innerWidth < 800) {
      this.isMobile = true
    }
  }
}
</script>

<style lang="less" scoped>
// 大于1200px
@media only screen and (min-width: 1200px) {
  .homecontainer {
    font-size: 1vw;

    .top {
      height: 450px;
      background-image: url("../../../public/img/about/About1.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        flex-direction: column;
        color: #fff;

        .t1 {
          :nth-child(1) {
            font-size: 36px;
          }

          :nth-child(2) {
            font-size: 28px;
          }
        }

        .t2 {
          margin: 65px 0;

          span {
            display: inline-block;
            text-align: center;
            width: 400px;
            font-weight: 300;
          }
        }
      }
    }

    // 简介
    .lvhuadai {
      height: 520px;
      background-image: url("../../../public/img/about/About2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .text {
        width: 500px;
        text-align: left;

        p {
          font-size: 16px;
        }

        .line {
          height: 6px;
          width: 40px;
          background-color: #397ff9;
          margin: 20px 0;
        }

        :nth-child(1) {
          font-size: 32px;
          color: #2E2E2E;
          font-weight: 500;
        }

        :nth-child(2) {
          font-size: 24px;
          color: #397ff9;
        }

        :nth-child(3) {
          font-size: 14px;
          color: #707070;
          line-height: 30px;

          span {
            font-size: 14px;
            color: #555555;
            line-height: 30px;
            font-weight: 800;
          }

        }

        .about-us {
          margin-top: 20px;
          height: 52px;
          width: 220px;
          background-color: #397FF9;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 42px;
            color: #fff;
          }
        }
      }
    }

    // 价值观
    .jiazhiguan {
      height: 567px;
      background-image: url("../../../public/img/about/About3.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .top-img {
        display: none;
      }

      ._center {
        display: none;
      }

      .center {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: right;

        .left {
          width: 420px;

          div {
            margin: 86px 0;
          }

          .t1 {
            h2 {
              font-size: 32px;
              font-weight: 400;
              line-height: 0px;
            }
          }

          .t2,
          .t3 {
            h3 {
              font-size: 26px;
              font-weight: 300;
            }

            p {
              margin: 20px 0;
              font-size: 18px;
              font-weight: 200;
              line-height: 32px;
              color: #FFFFFF;
              opacity: 0.69;
            }
          }

        }

        .right {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 69px;
            margin: 40px 0;
          }
        }
      }
    }

    // 证书
    .certificate {
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        padding: 40px 0;
        justify-content: space-between;
        align-items: center;
        color: #373737;
        text-align: left;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;

          .item-left {
            width: 282px;
            height: 196px;

            img {
              width: 282px;
              height: 144px;
              object-fit: cover;
            }

            div {
              display: flex;
              align-items: center;
              height: 52px;

              h4 {
                padding: 2px 14px;
                font-size: 18px;
                font-weight: 6 00;
                line-height: 20px;
                color: #3A3A3A;
                border-left: 5px solid rgba(57, 127, 249, );
                ;
              }
            }

          }
          .mobile{
            display: none;
          }

          .item-right {
            width: 304px;
            height: 203px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

      }
    }

    // 联系方式
    .info {
      height: 600px;
      background-image: url("../../../public/img/about/About2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        height: 466px;
        justify-content: space-between;
        align-items: center;
        color: #373737;
        text-align: left;
        background-color: #F3F7FF;

        .left {
          width: 500px;
          padding: 75px 20px 20px 55px;
          box-sizing: border-box;
          height: 100%;

          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }

          .text {
            margin-bottom: 36px;
            display: flex;
            align-items: center;

            span {
              font-size: 16px;
              line-height: 24px;
              color: #373737;
            }
          }

          .dizhi {
            display: flex;
            align-items: flex-start;

            div {
              display: flex;
              flex-direction: column;
            }
          }

          .code {
            display: flex;
            flex-direction: column;
            width: 90px;
            margin-left: 22px;
            justify-content: center;
            align-items: center;

            img {
              width: 110px;
              height: 110px;
              margin: 0;
            }

            span {
              font-size: 12px;
              color: #0079FE;
            }
          }

        }

        .right {
          height: 466px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
          }
        }
      }
    }
  }

}

// 小于1200px
@media only screen and (max-width: 1200px) {
  .homecontainer {
    font-size: 1vw;

    .top {
      background-image: url("../../../public/img/about/About1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: flex;
        flex-direction: column;
        color: #fff;
        padding-top: 20vw;

        .t1 {
          :nth-child(1) {
            font-size: 5vw;
          }

          :nth-child(2) {
            font-size: 3.5vw;
          }
        }

        .t2 {
          margin: 6vw 0 20vw 0;
          display: flex;

          span {
            margin-right: 10vw;
            display: inline-block;
            text-align: center;
            font-size: 3.5vw;
            font-weight: 300;
          }

          :nth-child(3) {
            margin-right: 0;
          }
        }
      }
    }

    // 简介
    .lvhuadai {
      background-image: url("../../../public/img/about/About2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }

      .text {
        margin-top: 6vw;
        text-align: left;
        padding: 4vw;

        p {
          font-size: 16px;
        }

        .line {
          height: 4px;
          width: 30px;
          background-color: #397ff9;
          margin: 20px 0;
        }

        :nth-child(1) {
          font-size: 6vw;
          color: #2E2E2E;
          font-weight: 500;
        }

        :nth-child(2) {
          font-size: 3.5vw;
          color: #397ff9;
        }

        :nth-child(3) {
          font-size: 3.5vw;
          color: #707070;
          line-height: 7vw;
          span {
            font-size: 3.5vw;
            color: #242424;
            line-height: 30px;
            font-weight: bold;
          }
        }

        .about-us {
          margin-top: 2vw;
          height: 6vw;
          width: 32vw;
          background-color: #397FF9;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 6vw;
            color: #fff;
          }
        }
      }

      .img {
        width: 80vw;
        margin-bottom: 6vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    // 价值观
    .jiazhiguan {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .center {
        display: none;
      }

      .top-img {
        width: 100vw;
      }

      ._center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: right;

        .text {
          background-image: url("../../../public/img/about/About3-m-2.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: left;
          box-sizing: border-box;
          padding: 4vw;
          width: 100vw;

          .t1 {
            h2 {
              font-size: 6vw;
              font-weight: 400;
              line-height: 0px;
              margin: 10vw 0;
            }
          }

          .t2,
          .t3 {
            margin-bottom: 6vw;

            h3 {
              font-size: 5vw;
              font-weight: 300;
              margin: 4vw 0;
            }

            p {
              margin: 2vw 0;
              font-size: 3vw;
              font-weight: 200;
              line-height: 7vw;
              color: #FFFFFF;
              opacity: 0.69;
            }
          }

        }

      }
    }

    // 证书
    .certificate {
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 100%;
        display: flex;
        padding: 6vw 0;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        color: #373737;
        text-align: left;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 4vw;
          margin-bottom: 4vw;

          .item-left {
            width: 50%;
            height: 32vw;
            img {
              width: 45vw;
              height: 22vw;

              object-fit: cover;
            }

            div {
              display: flex;
              align-items: center;
              height: 10vw;

              h4 {
                padding-left: 2vw;
                font-size: 3vw;
                font-weight: 600;
                line-height: 5vw;
                color: #3A3A3A;
                border-left: 5px solid rgba(57, 127, 249, );
                ;
              }
            }

          }
          .pc{
            display: none;
          }

          .item-right {
            width: 50%;
            height: 32vw;
            position: relative;
              top: -1vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

      }
    }

    // 联系方式
    .info {
      background-color: #fff;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        color: #373737;
        text-align: left;
        margin: 6vw 0;
        background-color: #F3F7FF;

        .left {
          box-sizing: border-box;
          padding: 4vw;

          img {
            width: 4vw;
            height: 4vw;
            margin-right: 2vw;
          }

          .text {
            margin-bottom: 4vw;
            display: flex;
            align-items: center;

            span {
              font-size: 4vw;
              color: #373737;
            }
          }

          .dizhi {
            display: flex;
            align-items: flex-start;

            div {
              display: flex;
              flex-direction: column;
            }
          }

          .code {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 4vw;
            position: absolute;

            img {
              width: 24vw;
              height: 24vw;
              margin: 0;
            }

            span {
              font-size: 12px;
              color: #0079FE;
            }
          }

        }

        .right {
          margin-top: 8vw;
          width: 90vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}</style>
