<template>
    <div class="navcontainer _navcontainer">
        <div class="logo _logo" @click="$router.push('/home')">
            <img src="/img/logo.png" alt="">
            <span>律化带</span>
        </div>

        <el-menu style="border-bottom: none;" :default-active="$route.path" router mode="horizontal" class="navbar">
            <el-menu-item v-for="route in routes" :key="route.path" :index="route.path"
                :class="$route.path === route.path ? 'is-active' : ''">
                {{ route.title }}
            </el-menu-item>
            <!-- <el-button class="login-button" @click="goTo('https://new.lvhuadai.cn/')">去登录</el-button> -->
        </el-menu>
    </div>
</template>

<script>


export default {
    name: 'navtop',
    components: {
    },
    data() {
        return {
            nalistshowflag: false,
            activeIndex: '1',
            routes: [
                { path: "/home", title: "首页" },
                { path: "/new_oa", title: "律化带案管&OA" },
                { path: "/hetongku", title: "律化带合同库" },
                { path: "/about", title: "关于我们" },
            ]
        }
    },
    methods: {
        goTo(url) {
            window.open(url)
        },
    },
}
</script>

<style lang="less" scoped>
// 大于1200px
@media only screen and (min-width: 1200px) {
    .navcontainer {
        display: flex;
        align-items: center;
        background-color: transparent;
        justify-content: space-between;
        height: 60px;
        position: absolute;
        width: 1200px;
        left: 50%;
        transform: translateX(-50%);
        color: #2E4C78;

        .navicon {
            display: none;
            cursor: pointer;
        }

        .navicon:hover {
            color: #2E4C78;
            font-weight: 600;

        }

        .logo {
            display: flex;
            align-items: center;
            margin-right: 300px;
            width: 100px;

            img {
                height: 36px;
                width: 36px;
                cursor: pointer;
            }

            span {
                font-size: 14px;
                margin-left: 5px;
                font-weight: 600;
            }
        }
        .navbar {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;

            .el-menu-item {
                margin-right: 30px;
                border-bottom: none;
                color: #2E4C78;

            }

            .el-menu-item:hover {
                background-color: transparent !important;
            }

            .el-menu-item.is-active {
                background: transparent !important;
                border-bottom: none;
                // border-bottom: 2px solid #409EFF;
            }

            .el-menu-item.is-active::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 25%;
                height: 4px;
                border-radius: 4px;
                background-color: #397FF9;
            }

            .login-button {
                color: #fff;
                background-color: #397FF9;
                height: 38px;
                width: 98px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                border: none;
                justify-content: center;
            }

            .login-button:hover {
                background-color: #71A5FF;
            }
        }
    }

}

// 小于1200px
@media only screen and (max-width: 1200px) {
    .navcontainer {
        display: flex;
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        position: absolute;
        padding: 2vw 4vw 4vw 4vw;
        // height: 10vw;
        color: #2E4C78;
        .logo {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24vw;
            
            img {
                height: 24px;
                width: 24px;
                cursor: pointer;
                // position: relative;
                // top: -4px;
            }
            
            span {
                display: inline-block;
                font-size: 3vw;
                height: 60px;
                margin-left: 5px;
                font-weight: 600;
                line-height: 60px;
            }
        }

        .navbar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: transparent;
            width: 100%;

            .el-menu-item {
                margin-right: 3vw;
                padding: 0;
                font-size: 3vw;
                color: #2E4C78;

            }

            .el-menu-item:hover {
                background-color: transparent !important;
            }

            .el-menu-item.is-active {
                background: transparent !important;
                border-bottom: none;
            }

            .el-menu-item.is-active::after {
                content: "";
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                width: 40%;
                height: 4px;
                border-radius: 4px;
                background-color: #397FF9;
            }
            .login-button {
                display: none;
            }
        }
    }
}
</style>