<template>
  <div id="app">
    <NavTop v-if="show"></NavTop>
    <router-view></router-view>
    <Footer v-if="show"></Footer>
  </div>
</template>

<script>
import NavTop from "./components/comcomponents/NavTop"
import Footer from "./components/comcomponents/Footer"

export default {
  name: 'App',
  components: {
    NavTop,Footer
  },
  data() {
    return {
      show:true
    }
  },
  created(){
    console.log(window.location.href);
    let href = window.location.href
    if(href.includes('skip')) {
      this.show = false
    } else {
      this.show = true
    }
  }
}
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
  padding: 0;
  margin: 0;
  // max-width: 100vw;
}

</style>
