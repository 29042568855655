import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home/Home.vue'
import SkipApplet from '../components/Home/skip.vue'
import OA from '../components/OA/OA'
import Hetongku from '../components/Hetongku/Hetongku'
import About from '../components/About/About'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home'
},
{
    path:'/home',
    component:Home,
},
{
    path:'/new_oa',
    component:OA,
},
{
    path:'/hetongku',
    component:Hetongku,
},
{
    path:'/about',
    component:About,
},
{
    path:'/skip',
    component:SkipApplet,
},
]
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
