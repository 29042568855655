<template>
  <div class="homecontainer _homecontainer">
    <div class="main-top-banner">
      <div class="center">
        <div class="lvhuadai">
          <p>律化带</p>
          <p>由资深律师团队与专业IT团队</p>
          <p>携手打造的新兴智慧化、数字化律所管理法律科技产品</p>
          <div class="line"></div>
          <p>LEGAL TECHNOLOGY PRODUCTS</p>
        </div>
        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp" ref="htk1">
          <img src="/img/home/home1-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="fuwu">
      <div class="title">服务群体</div>
      <div class="fuwu-card">
        <div class="card">
          <img src="/img/home/home2-1.png" alt="">
          <span>个人律师</span>
        </div>
        <div class="card">
          <img src="/img/home/home2-2.png" alt="">
          <span>律师事务所</span>
        </div>
        <div class="card">
          <img src="/img/home/home2-3.png" alt="">
          <span>律师团队</span>
        </div>
        <div class="card">
          <img src="/img/home/home2-4.png" alt="">
          <span>知识产权维权机构</span>
        </div>
      </div>

      <!-- 移动端 -->
      <div class="_fuwu-card">
        <div class="top">
          <div class="card">
            <img src="/img/home/home2-1.png" alt="">
            <span>个人律师</span>
          </div>
          <div class="card">
            <img src="/img/home/home2-2.png" alt="">
            <span>律师事务所</span>
          </div>
        </div>

        <div class="bottom">
          <div class="card">
            <img src="/img/home/home2-3.png" alt="">
            <span>律师团队</span>
          </div>
          <div class="card">
            <img src="/img/home/home2-4.png" alt="">
            <span>知识产权维权机构</span>
          </div>
        </div>
      </div>

    </div>

    <div class="jiejuefangan">
      <div class="text">解决方案</div>
      <div class="info">
        <div class="info-text">
          <p class="p1">
            律化带案件管理与OA系统
          </p>
          <p class="p1 line">
            —
          </p>
          <p class="p2">
            律化带案件管理与OA系统，简称“律化带”，为浙江律化带科技有限公司（Since 2015, HangZhou,
            China）旗下IT事业部研发的一款适用于知识产权律师事务所、律师团队、律师、知识产权维权机构（平台）的案件、项目管理与OA办公系统，旨在打造全方位列表式案件进度管理模式，实现移动办公，提高律所信息化与知识化管理能力
          </p>
          <p>
            <el-button class="button" @click="goTo('https://new.lvhuadai.cn/')">
              查看更多
            </el-button>
          </p>
        </div>
        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow info-img animate__animated animate__fadeInUp">
          <img src="/img/home/home3-1.png" alt="">
        </div>
      </div>
    </div>


    <div class="hetongku">
      <div class="center">

        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow info-img animate__animated animate__fadeInUp">
          <img src="/img/home/home4-1.png" alt="">
        </div>

        <div class="info">
          <div class="info-text">
            <p class="p1">
              <span style="color:#337bf8">律化带</span>合同库
            </p>
            <p class="p1" style="color:#337bf8">
              —
            </p>
            <p class="p2">
              由专业IT团队携手行业资深律师精心打磨，实现“律师分享”与“律化带自营”双轨制，是用户提供各领域优质合同与法律文书的分享平台，也是为律师创建个人宣传站与商城站的律师平台，能够建立用户与律师的紧密联系，为律师提供营销引流方案，实现互惠互利，合作共赢
            </p>
            <p>
              <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/')">
                查看更多
              </el-button>
            </p>
          </div>

        </div>
      </div>
      <!-- 移动端 -->
      <div class="_center">
        <div class="info">
          <div class="info-text">
            <p class="p1">
              <span style="color:#337bf8">律化带</span>合同库
            </p>
            <p class="p1 line" style="color:#337bf8">
              —
            </p>
            <p class="p2">
              由专业IT团队携手行业资深律师精心打磨，实现“律师分享”与“律化带自营”双轨制，是用户提供各领域优质合同与法律文书的分享平台，也是为律师创建个人宣传站与商城站的律师平台，能够建立用户与律师的紧密联系，为律师提供营销引流方案，实现互惠互利，合作共赢
            </p>
            <p>
              <el-button class="button" @click="goTo('https://hetongku.lvhuadai.cn/')">
                查看更多
              </el-button>
            </p>
          </div>
        </div>

        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow info-img animate__animated animate__fadeInUp">
          <img src="/img/home/home4-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="hezuohuoban">
      <p>合作伙伴</p>
      <div class="center">
        <div class="logo">
          <img src="/img/home/home-hzhb-1.png" alt="">
          <img src="/img/home/home-hzhb-2.png" alt="">
          <img src="/img/home/home-hzhb-3.png" alt="">
          <img src="/img/home/home-hzhb-4.png" alt="">
          <img src="/img/home/home-hzhb-5.png" alt="">
          <img src="/img/home/home-hzhb-6.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-7.png" alt="">
          <img src="/img/home/home-hzhb-8.png" alt="">
          <img src="/img/home/home-hzhb-9.png" alt="">
          <img src="/img/home/home-hzhb-10.png" alt="">
          <img src="/img/home/home-hzhb-11.png" alt="">
          <img src="/img/home/home-hzhb-12.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-13.png" alt="">
          <img src="/img/home/home-hzhb-14.png" alt="">
          <img src="/img/home/home-hzhb-15.png" alt="">
          <img src="/img/home/home-hzhb-16.png" alt="">
          <img src="/img/home/home-hzhb-17.png" alt="">
          <img src="/img/home/home-hzhb-18.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-19.png" alt="">
          <div class="text">
            ······期待您成为我们的合作伙伴！
          </div>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="_center">
        <div class="logo">
          <img src="/img/home/home-hzhb-1.png" alt="">
          <img src="/img/home/home-hzhb-2.png" alt="">
          <img src="/img/home/home-hzhb-3.png" alt="">
        </div>
        <div class="logo">
          <img src="/img/home/home-hzhb-4.png" alt="">
          <img src="/img/home/home-hzhb-5.png" alt="">
          <img src="/img/home/home-hzhb-6.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-7.png" alt="">
          <img src="/img/home/home-hzhb-8.png" alt="">
          <img src="/img/home/home-hzhb-9.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-10.png" alt="">
          <img src="/img/home/home-hzhb-11.png" alt="">
          <img src="/img/home/home-hzhb-12.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-13.png" alt="">
          <img src="/img/home/home-hzhb-14.png" alt="">
          <img src="/img/home/home-hzhb-15.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-16.png" alt="">
          <img src="/img/home/home-hzhb-17.png" alt="">
          <img src="/img/home/home-hzhb-18.png" alt="">
        </div>
        <div class="logo" style="margin-top: 20px;">
          <img src="/img/home/home-hzhb-19.png" alt="">
          <img style="visibility: hidden;" src="/img/home/home-hzhb-19.png" alt="">
          <img style="visibility: hidden;" src="/img/home/home-hzhb-19.png" alt="">
        </div>
      </div>
      <div class="_text">
        ······期待您成为我们的合作伙伴！
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {
      isMobile:false
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    if(window.innerWidth < 800) {
      this.isMobile  = true
    }
  },
  methods: {
    goTo(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="less" scoped>
// 大于1200px
@media only screen and (min-width: 1200px) {
  .homecontainer {
    font-size: 1vw;

    .main-top-banner {
      height: 520px;
      background-image: url("../../../public/img/home/home1.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .lvhuadai {
        width: 600px;
        text-align: left;
        color: #2F4664;

        p {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .line {
          height: 4px;
          width: 50px;
          background-color: #397ff9;
          margin: 20px 0;
        }

        :nth-child(1) {
          font-size: 38px;
          font-weight: 500;
        }

        :nth-child(2) {
          font-size: 30px;
        }

        :nth-child(3) {
          font-size: 24px;
          font-weight: 300;
        }

        :nth-child(4) {
          font-size: 24px;
          color: #397ff9;
        }

        :nth-child(5) {
          font-size: 14px;
          color: #9b9b9b;
          letter-spacing: 4px;
        }
      }
    }

    .fuwu {
      height: 380px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        line-height: 80px;
        font-size: 34px;
      }

      ._fuwu-card {
        display: none;
      }

      .fuwu-card {
        display: flex;
        justify-content: center;
        width: 1200px;
        align-items: center;
        height: 200px;

        .card {
          border-radius: 6px;
          overflow: hidden;
          margin-right: 20px;
          // margin-top: 40px;
          display: flex;
          justify-content: left;
          align-items: center;
          height: 132px;
          width: 270px;
          margin-right: 30px;
          box-shadow: 0px 3px 30px rgba(102, 128, 145, 0.16);
          font-size: 18px;
          font-weight: 600;

          img {
            width: 72px;
            height: 72px;
            margin: 0 16px;
          }
        }

        .card:hover {
          box-sizing: border-box;
          border-bottom: 5px solid #397ff9;
          float: left;
        }

        :nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }

    .jiejuefangan {
      height: 650px;
      background-image: url("../../../public/img/home/home3.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;

      .text {
        font-size: 34px;
        color: #fff;
      }

      .info {
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;

        .info-text {
          width: 500px;

          p {
            color: #fff;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            margin: 20px;
          }

          .p1 {
            font-size: 40px;
            line-height: 40px;
            font-weight: 500;
          }

          .p2 {
            width: 445px;
            font-size: 16px;
            line-height: 30px;
          }

          .button {
            width: 160px;
            height: 40px;
            background-color: #ffb83d;
            color: #fff;
            font-size: 14px;
            border: none;
            border-radius: 20px;
            position: relative;
            // left: -190px;
          }
        }

        .info-img {
          height: 480px;
          position: relative;
          left: 45px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .hetongku {
      height: 600px;
      background-image: url("../../../public/img/home/home4.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      ._center {
        display: none;
      }

      .center {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 600px;

        .info-text {
          width: 100%;
          padding: 20px;

          p {
            color: #252627;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            margin: 20px;
          }

          .p1 {
            font-size: 40px;
            line-height: 40px;
            font-weight: 600;
          }

          .p2 {
            // width: 45%;
            color: #7E8387;
            font-size: 16px;
            line-height: 30px;
            width: 403px;
          }

          .button {
            width: 160px;
            height: 40px;
            background-color: #ffb83d;
            color: #fff;
            font-size: 14px;
            border: none;
            border-radius: 20px;
            position: relative;
            // left: -190px;
          }
        }

        .info-img {
          width: 600px;

        }
      }
    }

    .hezuohuoban {
      height: 520px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      ._center{
        display:none ;
      }
      ._text{
        display: none;
      }
      .center {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      p {
        font-size: 34px;
        margin-bottom: 20px;
      }

      .logo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          height: 77px;
          width: 165px;
        }

        .text {
          margin-left: 40px;
          background-color: #F9FBFF;
          height: 77px;
          width: 100%;
          font-size: 22px;
          color: #3A5D8D;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 10px;
        }
      }
    }
  }

}

// 小于1200px
@media only screen and (max-width: 1200px) {
  ._homecontainer {
    font-size: 1vw;
    max-width: 100%;
    overflow: hidden;

    .main-top-banner {
      background-image: url("../../../public/img/home/home1-m.png");
      width: 100%;
      display: flex;
      justify-content: center;
      // align-items: center;

      .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .lvhuadai {
        padding: 4vw;
        padding-top: 20vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: #2F4664;

        p {
          font-size: 16vw;
          margin-bottom: 1vw;
        }

        .line {
          height: 4px;
          width: 40px;
          background-color: #397ff9;
          position: relative;
          border-radius: 4px;
          left: 50%;
          transform: translateX(-50%);
          // margin: 4vw 0;
        }

        :nth-child(1) {
          font-size: 6vw;
          font-weight: 600;
        }

        :nth-child(2) {
          font-size: 4vw;
        }

        :nth-child(3) {
          font-size: 3.5vw;
          font-weight: 300;
        }

        :nth-child(5) {
          display: none;
        }

      }

      .img {
        width: 75vw;
        margin-bottom: 6vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .fuwu {
      // height: 240px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        line-height: 6vw;
        font-size: 6vw;
        margin: 10vw 0 4vw 0;
      }

      .fuwu-card {
        display: none;
      }

      ._fuwu-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4vw;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 6vw;
        }

        .card {
          border-radius: 6px;
          overflow: hidden;
          margin-right: 4vw;
          display: flex;
          justify-content: left;
          align-items: center;
          height: 67px;
          width: 170px;
          height: 20vw;
          width: 43vw;
          box-shadow: 0px 3px 15px rgba(102, 128, 145, 0.16);
          font-size: 3.5vw;
          font-weight: 500;

          img {
            width: 36px;
            height: 36px;
            margin: 0 1vw;
          }
        }

        .card:hover {
          box-sizing: border-box;
          border-bottom: 5px solid #397ff9;
          float: left;
        }

        :nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }

    .jiejuefangan {
      background-image: url("../../../public/img/home/home3-m.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      box-sizing: border-box;

      .text {
        display: none;
      }

      .info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding:4vw 8vw;

        .info-text {
          p {
            color: #fff;
            font-size: 5vw;
            line-height: 36px;
            text-align: center;
            margin: 4vw 0;
          }

          .p1 {
            font-size: 6vw;
            line-height: 7vw;
            font-weight: 500;
          }

          .line {
            display: none;
          }

          .p2 {
            // width: 85vw;
            font-size: 3.5vw;
            line-height: 7vw;
          }

          .button {
            width: 160px;
            height: 40px;
            background-color: #ffb83d;
            color: #fff;
            font-size: 14px;
            border: none;
            border-radius: 20px;
            position: relative;
          }
        }

        .info-img {
          width: 75vw;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .hetongku {
      background-image: url("../../../public/img/home/home4-m.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: none;
      }

      ._center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4vw 8vw;

        .info {
          display: flex;
          align-items: center;
          justify-content: center;

          .info-text {
            p {
              color: #252627;
              font-size: 16px;
              line-height: 36px;
              text-align: center;
              margin: 4vw 0;
            }

            .line {
              display: none;
            }

            .p1 {
              font-size: 6vw;
              line-height: 7vw;
              font-weight: 600;
            }

            .p2 {
              // width: 45%;
              color: #7E8387;
              font-size: 3.5vw;
              line-height: 7vw;
            }

            .button {
              width: 160px;
              height: 40px;
              background-color: #ffb83d;
              color: #fff;
              font-size: 14px;
              border: none;
              border-radius: 20px;
              position: relative;
            }
          }

        }

        .info-img {
          width: 75vw;

          img {
            width: 100%;
            object-fit: cover;
          }

        }
      }

    }

    .hezuohuoban {
      // height: 440px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;


      .center {
        display: none;
      }

      ._center {
        padding: 4vw;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      p {
        font-size: 5vw;
        margin: 4vw;
      }

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          height: 38px;
          width: 82px;
          margin-right: 4vw;
        }

        :nth-last-child(1) {
          margin-right: 0%;
        }
      }

      ._text {
        // margin-left: 40px;
        background-color: #F9FBFF;
        height: 14vw;
        width: 100%;
        font-size: 3vw;
        color: #3A5D8D;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 2vw;
      }
    }
  }
}</style>
