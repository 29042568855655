<template>
    <div class="mainfooter _mainfooter">
        <div class="footerbody">
            <div class="qrCode">
                <h1><img class="logo" src="/img/logo.png" alt="">律化带</h1>
                <img src="/img/qrCode.png" alt="" class="code">
                <p class="text">扫描二维码了解我们</p>
            </div>
            <div style="width: 400px;">
                <h1>联系我们</h1>
                <p>电话：133-7257-6766</p>
                <p>销售(微信同号)：133-7257-6766</p>
                <!-- <p>邮箱：mail@lvhuadai.com</p> -->
                <p
                    @click="goTo('https://www.amap.com/search?id=B0FFKLPFKA&city=330106&geoobj=120.101694%7C30.263728%7C120.112618%7C30.268702&query_type=IDQ&query=%E6%B5%99%E6%B1%9F%E6%9D%AD%E7%9F%A5%E6%A1%A5%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80&zoom=17.5')">
                    地址：浙江省杭州市西溪路527号钱江浙商创投中心C座2楼</p>
            </div>
            <div style="width: 260px;" class="chanpin">
                <h1>律化带产品</h1>
                <a @click="goTo('https://new.lvhuadai.cn/')">律化带带案件管理与OA系统</a>
                <a @click="goTo('https://shop400860989.taobao.com/')">律化带家居商城</a>
                <a @mouseenter="showCode = true" @mouseleave="showCode = false">律化带名片</a>
                <div class="mingpianCode" v-if="showCode">
                    <img src="/img/qrCode2.png" alt="">
                    <div>
                        <span style="color: #397FF9;">手机扫一扫，</span><span>自动生成名片</span>
                    </div>
                </div>
            </div>
            <div style="width: 260px;" class="chanpin">
                <h1>友情链接</h1>
                <a @click="goTo('https://www.hangzhiqiao.com/')">杭知桥律师事务所</a>
                <a @click="goTo('https://hetongku.lvhuadai.cn/')">律化带合同库</a>
            </div>
            <div>
                <h1>关于我们</h1>
                <p @click="goTo('/about')">公司简介</p>
            </div>
        </div>

        <!-- 移动端 -->
        <div class="_footerbody">
            <div class="top">
                <div class="lianxi">
                    <h1>联系我们</h1>
                    <a>电话：133-7257-6766</a>
                    <a>销售(微信同号)：133-7257-6766</a>
                    <a @click="goTo('https://www.amap.com/search?id=B0FFKLPFKA&city=330106&geoobj=120.101694%7C30.263728%7C120.112618%7C30.268702&query_type=IDQ&query=%E6%B5%99%E6%B1%9F%E6%9D%AD%E7%9F%A5%E6%A1%A5%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80&zoom=17.5')">
                        地址：浙江省杭州市西溪路527号钱江浙商创投中心C座2楼</a>
                </div>


                <div class="chanpin">
                    <h1>律化带产品</h1>
                    <a @click="goTo('https://new.lvhuadai.cn/')">律化带案件管理与OA系统</a>
                    <a @click="goTo('https://shop400860989.taobao.com/')">律化带家居商城</a>
                    <div class="lvhudai-qrCode" @mouseenter="showCode = true" @mouseleave="showCode = false">律化带名片
                        <div class="mingpianCode" v-if="showCode">
                            <img src="/img/qrCode2.png" alt="">
                            <div style="text-align: center;">
                                <span style="color: #397FF9;">扫一扫，</span><span>自动生成名片</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom">
                <div style="display: flex; flex-direction: column;">
                    <div class="about">
                        <h1>关于我们</h1>
                        <p @click="goTo('/about')">公司简介</p>
                    </div>
                    <div class="lianxi" style="margin-top: 2vw;">
                        <h1>友情链接</h1>
                        <a @click="goTo('https://www.hangzhiqiao.com/')">杭知桥律师事务所</a>
                        <a @click="goTo('https://hetongku.lvhuadai.cn/')">律化带合同库</a>
                    </div>
                </div>
                <div class="qrCode">
                    <h1><img class="logo" src="/img/logo.png" alt="">律化带</h1>
                    <img src="/img/qrCode.png" alt="" class="code">
                    <p class="text">扫描二维码了解我们</p>
                </div>

            </div>
        </div>
        <div class="line"></div>
        <div class="footerbottom _footerbottom">
            <!-- <span>浙公网安备 33010602005562号</span> -->
            <span>浙江律化带科技有限公司 版权所有 © 2016</span><span class="link"
                @click="goTo('https://beian.miit.gov.cn/#/Integrated/recordQuery')">浙ICP备15019258号-5</span>
        </div>
    </div>
</template>

<script>


export default {
    name: 'mainfooter',
    components: {
    },
    data() {
        return {
            showCode: false
        }
    },
    methods: {
        goTo(url) {
            window.open(url)
        }
    },
}
</script>

<style lang="less" scoped>
@media only screen and (min-width: 1200px) {
    .mainfooter {
        display: flex;
        flex-direction: column;
        background-color: #151515;
        width: 100%;
        color: #8B8989;

        ._footerbody {
            display: none;
        }

        .footerbody {
            display: flex;
            justify-content: center;
            text-align: left;
            padding: 20px 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            // margin: 0 10%;
            padding-left: 100px;

            div {
                width: 200px;
                margin-right: 20px;

                h1 {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 800;
                    margin: 10px 0;

                    img {
                        height: 26px;
                        width: 26px;
                        margin-right: 8px !important;
                    }
                }

                p {
                    font-size: 14px;
                    margin: 20px 0;
                }

                p:hover {
                    color: #FFF;
                    cursor: pointer;
                }
                a {
                    font-size: 14px;
                    margin: 10px 0;
                }

                a:hover {
                    color: #FFF;
                    cursor: pointer;
                }

            }

            :nth-last-child(1) {
                margin-right: 0;
            }

            .qrCode {
                .code {
                    margin-top: 10px;
                    height: 109px;
                    width: 109px;
                }

                .text {
                    font-size: 12px;
                }
            }

            .chanpin {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }

            .mingpianCode {
                height: 166px;
                width: 166px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 7px;
                position: absolute;
                top: -40px;
                left: -50px;
                padding: 10px;
                margin-right: 0px;
                box-sizing: border-box;

                img {
                    width: 112px;
                    height: 112px;
                    object-fit: cover;
                }

                div {
                    width: 100%;
                }

                span {
                    font-size: 12px;
                }
            }

            .mingpianCode::before {
                content: '';
                position: absolute;
                left: 73px;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #ffffff transparent;
                bottom: -8px;
                transform: rotate(180deg);
            }
        }

        .line {
            border-bottom: 0.5px solid #333333;
        }

        .footerbottom {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 7px 0;

            :nth-child(1) {
                margin-right: 50px;
            }

            span {
                font-size: 10px;
            }

            .link {
                border-bottom: 1px #8B8989 solid;
                cursor: pointer;
            }
        }
    }

}

@media only screen and (max-width: 1200px) {
    .mainfooter {
        display: flex;
        flex-direction: column;
        background-color: #151515;
        width: 100%;
        color: #8B8989;

        .footerbody {
            display: none;
        }

        ._footerbody {
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;
            text-align: left;
            padding: 2vh 4vw;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .top {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;

                .lianxi {
                    display: flex;
                    flex-direction: column;
                    width: 55vw;
                    margin-right: 2vw;
                }

                .chanpin {
                    display: flex;
                    flex-direction: column;
                    width: 45vw;
                    margin-right: 0;
                }
            }

            .bottom {
                display: flex;
                align-items: flex-start;

                .about {
                    width: 55vw;
                    margin-right: 2vw;
                }
                .lianxi{
                    display: flex;
                    flex-direction: column;
                }
            }

            div {
                h1 {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 0.8rem;
                    font-weight: 600;
                    margin: 1vw 0;

                    img {
                        height: 20px;
                        width: 20px;
                        margin-right: 8px !important;
                    }
                }

                p {
                    font-size: 0.8rem;
                    margin: 1vh 0;
                }

                p:hover {
                    color: #FFF;
                    cursor: pointer;
                }
                a {
                    font-size: 0.8rem;
                    margin: 0.8vh 0;
                }

                a:hover {
                    color: #FFF;
                    cursor: pointer;
                }

                .lvhudai-qrCode {
                    margin: 0.8vh 0;
                    font-size: 0.8rem;
                    position: relative;
                }

                .lvhudai-qrCode:hover {
                    cursor: pointer;
                }
            }


            .qrCode {
                width: 45vw;
                display: flex;
                flex-direction: column;
                // justify-content: flex-start;
                align-items: flex-start;

                .code {
                    margin-top: 10px;
                    height: 96px;
                    width: 96px;
                }

                .text {
                    font-size: 0.8rem;
                    // scale: 0.8;
                }
            }

            .chanpin {
                position: relative;
            }

            .mingpianCode {
                height: 36vw;
                width: 36vw;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 7px;
                position: absolute;
                // top: -150px;
                bottom: 24px;
                left: -50px;
                padding: 1vw;
                margin-right: 0px;
                box-sizing: border-box;

                img {
                    width: 26vw;
                    height: 26vw;
                    object-fit: cover;
                }

                div {
                    width: 100%;
                }

                span {
                    font-size: 0.7rem;
                }
            }

            .mingpianCode::before {
                content: '';
                position: absolute;
                // left: 73px;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #ffffff transparent;
                bottom: -8px;
                transform: rotate(180deg);
            }
        }

        .line {
            border-bottom: 0.5px solid #333333;
        }

        .footerbottom {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin: 7px 0;
            padding: 10px;

            span {
                font-size: 10px;
            }

            .link {
                border-bottom: 1px #8B8989 solid;
                cursor: pointer;
            }
        }
    }
}</style>