<template>
  <div class="homecontainer _homecontainer">
    <div class="main-top-banner">
      <div class="center _center">
        <div class="lvhuadai">
          <p class="p1">律化带案件管理与OA系统</p>
          <p class="p2">律师事务所数字化方案解决机构</p>
          <div>
            <el-button class="button" @click="goTo('https://new.lvhuadai.cn/')">进入律化带OA系统</el-button>
          </div>
        </div>
        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow img animate__animated animate__fadeInUp" ref="htk1">
          <img src="/img/oa/OA1-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="guanli">
      <!-- PC -->
      <div class="center">
        <div class="title">七大管理模块</div>
        <div class="guanli-card">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-1.png" alt="">
              <span>客户管理</span>
            </div>
            <div class="card-info">客户、委托人、对方当事人、第三方信息、含联系人/案源人/办案成员、银行与开票消息、证照存储，客户名下关联的合同、案件、到款、收支等信息
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-2.png" alt="">
              <span>营销客户</span>
            </div>
            <div class="card-info">
              客户管理中的营销客户模块，可以外部导入一些客户名单，对客户名单分配给具体的营销人员，或营销人员添加营销客户作为公共开发客户，记录营销客户的沟通过程和记录，可以把公共开发客户通过联系分配到具体营销人员的私人营销客户，公共开发客户和私人营销客户可以互相转移。
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-3.png" alt="">
              <span>案件管理</span>
            </div>
            <div class="card-info">民行仲案：诉讼案件全流程管理，含案件基本信息、诉讼信息、案件结果、收益信息、开庭信息、关联信息、备注信息，民行仲案模块包括了民事案件、商事案件、行政案件、仲裁案件等类别。
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-4.png" alt="">
              <span>批量诉讼</span>
            </div>
            <div class="card-info">
              为本系统的重点，增加了权利人的维权项目，先按项目管理，针对一个权利人的一个或多个知识产权权利维权上所涉及共性内容的统一管理，项目下具体的批量知识产权案件列表以及每一个案件，针对同一项目所涉及多个案件相关内容的统计；案件节点流程管理，便于监控案件进程；细致字段并实现批量复制和批量导入导出；批量诉讼中的批量知产针对知识产权案件原告多案件的特点，维权案件的基本信息、侵权平台、销量、金额、标的、公证信息、案件进度、结案与结算、开庭、到款信息，字段细致多样。调查取证：包括线索和公证信息管理；含取证地点、取证对象、物证管理、公证书管理、与诉讼衔接，列表式全过程管理、案件统计；可以将线索转成公证，公证可以转成批量案件。
              <br>
              法律顾问：顾问过程的事务与文件管理，顾问时间/内容/期限/人员，到期提前提醒，附件文档记录；<br>
              非诉法律：非诉项目的流程管理，基本信息，非诉内容记录，非诉结果、附件文档记录；<br>
              专利无效：专利无效宣告程序的全流程管理，主要是涉案专利信息、双方当事人和口审时间、地点、案件结果。<br>
              商标无效：商标无效宣告程序的全流程管理，主要是涉案商标、双方当事人和口审时间、地点、案件结果。<br>
              涉外知产：包括PCT申请、国际专利和国际商标的信息登记。<br>
              国内知产：实现软件登记与文学版权登记的文件和信息管理，基本信息、期限和人员；
            </div>
          </div>

        </div>
        <div class="guanli-card" style="margin-top: 30px;">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-5.png" alt="">
              <span>项目申报</span>
            </div>
            <div class="card-info">科技项目（国家高新、创新基金、火炬项目等）的全周期列表管理，详细信息、期限和人员；</div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-6.png" alt="">
              <span>合同财务</span>
            </div>
            <div class="card-info">
              包括合同登记与审批，合同关联人员与案件信息，包括案件收支、到款、开票、付款，可查全所、全部门及个人的案件收支情况，并进行列表与统计，其中付款包括案件付款、事务付款、差旅付款等，实现收入与支出关联具体案件和具体经办人/律师/成员，收支关联到合同与客户可以进行分析营收，可细分款项内容，实现全程线上化。
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-7.png" alt="">
              <span>综合管理</span>
            </div>
            <div class="card-info">
              包括印章、公函、请示等事项、通知、公益、快递与文印、会议与培训、人事行政、资产等内务性管理模块，实现行政事务在线化与流程化，做到分工明确，责任到组到人；印章版块是对盖章登记的管理实现公章使用、公函使用等规制；请示等事项主要是对案件之外的行政性事务管理；通知能够及时将律所信息传达至律所成员；快递与文印的登记便于律所统计成本和支出。
            </div>
          </div>
          <div class="card" style="visibility: hidden;">
          </div>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="_center">
        <div class="title">七大管理模块</div>
        <div class="guanli-card">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-1.png" alt="">
              <span>客户管理</span>
            </div>
            <div class="card-info">客户、委托人、对方当事人、第三方信息、含联系人/案源人/办案成员、银行与开票消息、证照存储，客户名下关联的合同、案件、到款、收支等信息
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-2.png" alt="">
              <span>营销客户</span>
            </div>
            <div class="card-info">
              客户管理中的营销客户模块，可以外部导入一些客户名单，对客户名单分配给具体的营销人员，或营销人员添加营销客户作为公共开发客户，记录营销客户的沟通过程和记录，可以把公共开发客户通过联系分配到具体营销人员的私人营销客户，公共开发客户和私人营销客户可以互相转移。
            </div>
          </div>
        </div>
        <div class="guanli-card">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-3.png" alt="">
              <span>案件管理</span>
            </div>
            <div class="card-info">民行仲案：诉讼案件全流程管理，含案件基本信息、诉讼信息、案件结果、收益信息、开庭信息、关联信息、备注信息，民行仲案模块包括了民事案件、商事案件、行政案件、仲裁案件等类别。
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-4.png" alt="">
              <span>批量诉讼</span>
            </div>
            <div class="card-info">
              为本系统的重点，增加了权利人的维权项目，先按项目管理，针对一个权利人的一个或多个知识产权权利维权上所涉及共性内容的统一管理，项目下具体的批量知识产权案件列表以及每一个案件，针对同一项目所涉及多个案件相关内容的统计；案件节点流程管理，便于监控案件进程；细致字段并实现批量复制和批量导入导出；批量诉讼中的批量知产针对知识产权案件原告多案件的特点，维权案件的基本信息、侵权平台、销量、金额、标的、公证信息、案件进度、结案与结算、开庭、到款信息，字段细致多样。调查取证：包括线索和公证信息管理；含取证地点、取证对象、物证管理、公证书管理、与诉讼衔接，列表式全过程管理、案件统计；可以将线索转成公证，公证可以转成批量案件。
              <br>
              法律顾问：顾问过程的事务与文件管理，顾问时间/内容/期限/人员，到期提前提醒，附件文档记录；<br>
              非诉法律：非诉项目的流程管理，基本信息，非诉内容记录，非诉结果、附件文档记录；<br>
              专利无效：专利无效宣告程序的全流程管理，主要是涉案专利信息、双方当事人和口审时间、地点、案件结果。<br>
              商标无效：商标无效宣告程序的全流程管理，主要是涉案商标、双方当事人和口审时间、地点、案件结果。<br>
              涉外知产：包括PCT申请、国际专利和国际商标的信息登记。<br>
              国内知产：实现软件登记与文学版权登记的文件和信息管理，基本信息、期限和人员；
            </div>
          </div>
        </div>

        <div class="guanli-card">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-5.png" alt="">
              <span>项目申报</span>
            </div>
            <div class="card-info">科技项目（国家高新、创新基金、火炬项目等）的全周期列表管理，详细信息、期限和人员；</div>
          </div>
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-6.png" alt="">
              <span>合同财务</span>
            </div>
            <div class="card-info">
              包括合同登记与审批，合同关联人员与案件信息，包括案件收支、到款、开票、付款，可查全所、全部门及个人的案件收支情况，并进行列表与统计，其中付款包括案件付款、事务付款、差旅付款等，实现收入与支出关联具体案件和具体经办人/律师/成员，收支关联到合同与客户可以进行分析营收，可细分款项内容，实现全程线上化。
            </div>
          </div>
        </div>
        <div class="guanli-card last">
          <div class="card">
            <div class="card-title">
              <img src="/img/oa/OA2-7.png" alt="">
              <span>综合管理</span>
            </div>
            <div class="card-info">
              包括印章、公函、请示等事项、通知、公益、快递与文印、会议与培训、人事行政、资产等内务性管理模块，实现行政事务在线化与流程化，做到分工明确，责任到组到人；印章版块是对盖章登记的管理实现公章使用、公函使用等规制；请示等事项主要是对案件之外的行政性事务管理；通知能够及时将律所信息传达至律所成员；快递与文印的登记便于律所统计成本和支出。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gongneng">
      <h1>十二大功能</h1>
      <div class="main">
        <div class="left">
          <div style="margin-top: 60px;">
            <p>{{ infoTitle }}</p>
            <p>—</p>
            <p>{{ infoContent }}</p>
          </div>
        </div>
        <div class="right">
          <div class="row">
            <div class="info" @mouseenter="changeInfo('1')">
              <img src="/img/oa/OA3-1.png" alt="">
              <span>利冲检索</span>
            </div>
            <div class="info" @mouseenter="changeInfo('2')">
              <img src="/img/oa/OA3-2.png" alt="">
              <span>提醒功能</span>
            </div>
            <div class="info" @mouseenter="changeInfo('3')">
              <img src="/img/oa/OA3-3.png" alt="">
              <span>附件文档</span>
            </div>
            <div class="info" @mouseenter="changeInfo('4')">
              <img src="/img/oa/OA3-4.png" alt="">
              <span>审批功能</span>
            </div>
          </div>
          <div class="row">
            <div class="info" @mouseenter="changeInfo('5')">
              <img src="/img/oa/OA3-5.png" alt="">
              <span>关联功能</span>
            </div>
            <div class="info" @mouseenter="changeInfo('6')">
              <img src="/img/oa/OA3-6.png" alt="">
              <span>可视化</span>
            </div>
            <div class="info" @mouseenter="changeInfo('7')">
              <img src="/img/oa/OA3-7.png" alt="">
              <span>EXCEL导入导出</span>
            </div>
            <div class="info" @mouseenter="changeInfo('8')">
              <img src="/img/oa/OA3-8.png" alt="">
              <span>律聊功能</span>
            </div>
          </div>
          <div class="row">
            <div class="info" @mouseenter="changeInfo('9')">
              <img src="/img/oa/OA3-9.png" alt="">
              <span>结算</span>
            </div>
            <div class="info" @mouseenter="changeInfo('10')">
              <img src="/img/oa/OA3-10.png" alt="">
              <span>客户端</span>
            </div>
            <div class="info" @mouseenter="changeInfo('11')">
              <img src="/img/oa/OA3-11.png" alt="">
              <span>小程序</span>
            </div>
            <div class="info" @mouseenter="changeInfo('12')">
              <img src="/img/oa/OA3-12.png" alt="">
              <span>在线生成公函</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 移动端 -->
      <div class="_main">
        <div class="top">
          <div class="left">
            <p>{{ infoTitle }}</p>
            <p>—</p>
            <p>{{ infoContent }}</p>
          </div>
          <div class="right">
            <div class="row">
              <div class="info" @mouseenter="changeInfo('1')">
                <img src="/img/oa/OA3-1.png" alt="">
                <span>利冲检索</span>
              </div>
              <div class="info" @mouseenter="changeInfo('2')">
                <img src="/img/oa/OA3-2.png" alt="">
                <span>提醒功能</span>
              </div>
            </div>
            <div class="row">
              <div class="info" @mouseenter="changeInfo('3')">
                <img src="/img/oa/OA3-3.png" alt="">
                <span>附件文档</span>
              </div>
              <div class="info" @mouseenter="changeInfo('4')">
                <img src="/img/oa/OA3-4.png" alt="">
                <span>审批功能</span>
              </div>
            </div>
            <div class="row">
              <div class="info" @mouseenter="changeInfo('5')">
                <img src="/img/oa/OA3-5.png" alt="">
                <span>关联功能</span>
              </div>
              <div class="info" @mouseenter="changeInfo('6')">
                <img src="/img/oa/OA3-6.png" alt="">
                <span>可视化</span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="left">
            <div class="row">
              <div class="info" @mouseenter="changeInfo('7')">
                <img src="/img/oa/OA3-7.png" alt="">
                <span>EXCEL导入导出</span>
              </div>
              <div class="info" @mouseenter="changeInfo('8')">
                <img src="/img/oa/OA3-8.png" alt="">
                <span>律聊功能</span>
              </div>
            </div>
            <div class="row">
              <div class="info" @mouseenter="changeInfo('9')">
                <img src="/img/oa/OA3-9.png" alt="">
                <span>结算</span>
              </div>
              <div class="info" @mouseenter="changeInfo('10')">
                <img src="/img/oa/OA3-10.png" alt="">
                <span>客户端</span>
              </div>
            </div>
            <div class="row">
              <div class="info" @mouseenter="changeInfo('11')">
                <img src="/img/oa/OA3-11.png" alt="">
                <span>小程序</span>
              </div>
              <div class="info" @mouseenter="changeInfo('12')">
                <img src="/img/oa/OA3-12.png" alt="">
                <span>在线生成公函</span>
              </div>
            </div>
          </div>

          <div class="right">
            <div>
              <p>{{ infoTitle2 }}</p>
              <p>—</p>
              <p>{{ infoContent2 }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="youshi">
      <div class="title">我们的优势</div>
      <div class="youshi-card">
        <div class="card">
          <div class="card-title">
            <span>预盖多数案件场景</span>
            <img src="/img/oa/OA4-1.png" alt="">
          </div>
          <div class="card-info">
            <p><img src="/img/oa/OA4-5.png" alt="">产品矩阵丰富</p>
            <p><img src="/img/oa/OA4-5.png" alt="">涵盖各类诉讼类型</p>
            <p><img src="/img/oa/OA4-5.png" alt="">国内外知产案件管理</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span>全业务流程闭环</span>
            <img src="/img/oa/OA4-2.png" alt="">
          </div>
          <div class="card-info">
            <p><img src="/img/oa/OA4-5.png" alt="">全模块流程驱动</p>
            <p><img src="/img/oa/OA4-5.png" alt="">管理过程清晰重现</p>
            <p><img src="/img/oa/OA4-5.png" alt="">一目了然</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span>数据安全保障</span>
            <img src="/img/oa/OA4-3.png" alt="">
          </div>
          <div class="card-info">
            <p><img src="/img/oa/OA4-5.png" alt="">产品自主研发</p>
            <p><img src="/img/oa/OA4-5.png" alt="">数据加密算法</p>
            <p><img src="/img/oa/OA4-5.png" alt="">保护客户数据资产</p>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span>系统稳定运行</span>
            <img src="/img/oa/OA4-4.png" alt="">
          </div>
          <div class="card-info">
            <p><img src="/img/oa/OA4-5.png" alt="">系统稳定率99.999%</p>
            <p><img src="/img/oa/OA4-5.png" alt="">系统运行高效</p>
            <p><img src="/img/oa/OA4-5.png" alt="">优化系统性能</p>
          </div>
        </div>
      </div>
      <div class="_youshi-card">
        <div class="card-list">
          <div class="card">
            <div class="card-title">
              <span>预盖多数案件场景</span>
              <img src="/img/oa/OA4-1.png" alt="">
            </div>
            <div class="card-info">
              <p><img src="/img/oa/OA4-5.png" alt="">产品矩阵丰富</p>
              <p><img src="/img/oa/OA4-5.png" alt="">涵盖各类诉讼类型</p>
              <p><img src="/img/oa/OA4-5.png" alt="">国内外知产案件管理</p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <span>全业务流程闭环</span>
              <img src="/img/oa/OA4-2.png" alt="">
            </div>
            <div class="card-info">
              <p><img src="/img/oa/OA4-5.png" alt="">全模块流程驱动</p>
              <p><img src="/img/oa/OA4-5.png" alt="">管理过程清晰重现</p>
              <p><img src="/img/oa/OA4-5.png" alt="">一目了然</p>
            </div>
          </div>
        </div>

          <div class="card-list"> 
            <div class="card">
              <div class="card-title">
                <span>数据安全保障</span>
                <img src="/img/oa/OA4-3.png" alt="">
              </div>
              <div class="card-info">
                <p><img src="/img/oa/OA4-5.png" alt="">产品自主研发</p>
                <p><img src="/img/oa/OA4-5.png" alt="">数据加密算法</p>
                <p><img src="/img/oa/OA4-5.png" alt="">保护客户数据资产</p>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <span>系统稳定运行</span>
                <img src="/img/oa/OA4-4.png" alt="">
              </div>
              <div class="card-info">
                <p><img src="/img/oa/OA4-5.png" alt="">系统稳定率99.999%</p>
                <p><img src="/img/oa/OA4-5.png" alt="">系统运行高效</p>
                <p><img src="/img/oa/OA4-5.png" alt="">优化系统性能</p>
              </div>
            </div>
          </div>

      </div>
    </div>


    <div class="tongchou">
      <div class="center">
        <div class="info">
          <div class="info-text">
            <p class="p1">
              律所全面统筹
            </p>
            <div class="line"></div>
            <p class="p2">
              支持律所私有化部署，集成律化带案件管理与○A系统全部功能，可依据律所实际管理模式配置相应字段及功能，构建律所内部案件管理系统，灵活管理且安全性高。
            </p>
            <p class="p2">
              定制个性使用方案，依据律所实际管理模式配置相应字段及功能，成本较低又可构建符合律所实际发展的案件管理系统
            </p>
          </div>
        </div>
        <div :data-wow-offset="isMobile ? 200 : 400" data-wow-duration="2s" data-wow-delay="0.1s"
          class="wow info-img animate__animated animate__fadeInUp">
          <img src="/img/oa/OA5-1.png" alt="">
        </div>
      </div>
    </div>

    <div class="shouhou">
      <div class="title">全面售后保障</div>
      <div class="shouhou-card">
        <div>
          <img src="/img/oa/shouhou1.png" alt="">
        </div>
        <div class="text">
          <h3>系统不间断升级</h3>
          <p>通过客户调查，依据客户建议以及问题反馈，持续优化系统</p>
        </div>
        <div>
          <img src="/img/oa/shouhou2.png" alt="">
        </div>
      </div>

      <div class="shouhou-card">
        <div class="text">
          <h3>长期技术支持</h3>
          <p>5x8小时在线客服，特殊情况紧急响应，及时准确地解答用户使用中的各种问题</p>
        </div>
        <div>
          <img src="/img/oa/shouhou3.png" alt="">
        </div>
        <div class="text">
          <h3>定期客户追踪</h3>
          <p>定期上门/通讯拜访，主动了解产品使用情况，获取客户新需求</p>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="_shouhou">
      <div class="title">全面售后保障</div>
      <div class="shouhou-card">
        <div>
          <img src="/img/oa/shouhou1.png" alt="">
        </div>
        <div class="text">
          <h3>系统不间断升级</h3>
          <p>通过客户调查，依据客户建议以及问题反馈，持续优化系统</p>
        </div>

      </div>

      <div class="shouhou-card">
        <div class="text">
          <h3>长期技术支持</h3>
          <p>5x8小时在线客服，特殊情况紧急响应，及时准确地解答用户使用中的各种问题</p>
        </div>
        <div>
          <img src="/img/oa/shouhou2.png" alt="">
        </div>
      </div>
      <div class="shouhou-card" style="margin-bottom: 10vw;">
        <div>
          <img src="/img/oa/shouhou3.png" alt="">
        </div>
        <div class="text">
          <h3>定期客户追踪</h3>
          <p>定期上门/通讯拜访，主动了解产品使用情况，获取客户新需求</p>
        </div>
      </div>
    </div>


    <div class="shiyong">
      <h1>律化带案件管理与OA系统 免费试用</h1>
      <h3>请咨询律化带客服(13372576766，微信同号) 申请试用账号</h3>
      <p> <img src="/img/about/About4-4.png" alt=""></p>
      <!-- <el-button class="button" @click="goTo('https://new.lvhuadai.cn/')">立即使用</el-button> -->
    </div>


  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      isMobile:false,
      infoTitle: '利冲检索',
      infoContent: '客户利益冲突检索，可以检索到已经在的客户、委托人、对方当事人、第三人等可能的利益冲突单位，客户或利益冲突对应的案件负责人，从而进行高危客户提示。',
      infoTitle2: 'EXCEl导入导出',
      infoContent2: '提供客户查询账号，客户通过账号登录系统可直接查询与客户相关案件或项目的进展流程以及对应文件，客户还可以通过上述律聊与办案律师线上交流，传达指令，上传各类文件，实现线上与客户互动办公，节省向客户汇报程序。',
    }
  },
  components: {
  },
  mounted() {
    new this.$wow.WOW().init();
    if(window.innerWidth <= 800) {
      this.isMobile  = true
    }
  },
  methods: {
    changeInfo(type) {
      if (type == '1') {
        this.infoTitle = '利冲检索'
        this.infoContent = '客户利益冲突检索，可以检索到已经在的客户、委托人、对方当事人、第三人等可能的利益冲突单位，客户或利益冲突对应的案件负责人，从而进行高危客户提示。'
      } else if (type == '2') {
        this.infoTitle = '提醒功能'
        this.infoContent = '开庭/财产保全续保/会议/事件/任务/缴费提醒/劳动人事到期，形成任务列表，系统设置或自定义提醒频率（每周/每月/每年）与方式（PC在线、短信、小程序）；'
      } else if (type == '3') {
        this.infoTitle = '附件文档'
        this.infoContent = '每条数据（案件/事项等）提供附件文档库，供上传与下载该条数据事项对应的所有文字、文件、图片附件，如合同、诉讼资料、客户材料、审批文件等；'
      } else if (type == '4') {
        this.infoTitle = '审批功能'
        this.infoContent = '立案/结案审批、合同、案件、及综合模块中的印章、请示、用款、报销以及后勤管理都可提交审批，并且在首页中有“我发起、抄送我、我审批”的统计数据及时提醒。'
      } else if (type == '5') {
        this.infoTitle = '关联功能'
        this.infoContent = '客户名下关联的合同、案件、到款、收支等信息；案件与合同、合同与到款等多维度关联，客户、合同与负责人或办案人员的关联，关联后方便查询，方便统计。'
      } else if (type == '6') {
        this.infoTitle = '可视化（统计）'
        this.infoContent = '客户、合同、案件（标的、管辖、案由等）、到款（代理费、官费、代收费用）、支出（案件支出、官费、差旅、报销）、综合管理等实现统计及可视化图标，并实现导出功能，多维度（月、年或选定期限），方便大数据管理。某个项目的统计情况、每个律师的统计情况。'
      } else if (type == '7') {
        this.infoTitle2 = 'EXCEl导入导出'
        this.infoContent2 = '可以通过EXCEl批量导入各类案件的信息，也可以批量导出案件的信息成excel表格，导出后进行统计、结算、查看等，还可以根据查询结果后进行选择性批量导入导出。'
      } else if (type == '8') {
        this.infoTitle2 = '律聊功能'
        this.infoContent2 = '各类案件、客户、合同、到款、综合、OA模块设置了各条数据所对应的成员之间的聊天工具，可以内部成员聊天，也可以内部成员与客户聊天两种方式，聊天可以文字、图片，上传附件，实现在具体案件中有交流信息或指令的留存，供日后确定或查看，留痕溯源，在客户聊天中可以发送指令接受客户的指令。'
      } else if (type == '9') {
        this.infoTitle2 = '结算'
        this.infoContent2 = '针对批量维权案件，可依据设定的结算公式按结算周期进行委托人结算或渠道方结算，便捷结算统计。'
      } else if (type == '10') {
        this.infoTitle2 = '客户端'
        this.infoContent2 = '提供客户查询账号，客户通过账号登录系统可直接查询与客户相关案件或项目的进展流程以及对应文件，客户还可以通过上述律聊与办案律师线上交流，传达指令，上传各类文件，实现线上与客户互动办公，节省向客户汇报程序。'
      } else if (type == '11') {
        this.infoTitle2 = '小程序'
        this.infoContent2 = '可以在手机上查看PC端录入的数据，在PC上的功能都能在移动端小程序上实现，并可以对其中的信息进行添加、修改，手机端操作简单方便。'
      } else if (type == '12') {
        this.infoTitle2 = '在线生成公函'
        this.infoContent2 = '印章单元中，可自动根据填写的案件信息生成对外公函文档，检测是否重复开公函。'
      }
    },
    goTo(url) {
      window.open(url)
    }
  },
}
</script>

<style lang="less" scoped>
// 大于1200px
@media only screen and (min-width: 1200px) {
  .homecontainer {
    font-size: 1vw;

    .main-top-banner {
      height: 520px;
      background-image: url("../../../public/img/oa/OA1.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .lvhuadai {
        width: 500px;
        text-align: left;

        p {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .p1 {
          font-size: 40px;
          font-weight: 600;
          margin-bottom: 20px;
          color: #242526;
        }

        .p2 {
          font-size: 26px;
          color: #65666D;
        }

        .button {
          margin-top: 30px;
          font-size: 16px;
          color: #fff;
          height: 46px;
          width: 200px;
          border-radius: 8px;
          background-color: #397ff9;
        }

        .button:hover {
          background-color: #71A5FF;
        }
      }

      .img {
        position: relative;
        width: 654px;
        top: 20px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .guanli {
      height: 810px;
      background-image: url("../../../public/img/oa/OA2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ._center {
        display: none;
      }

      .center {
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
      }

      .title {
        font-size: 34px;
        color: #2E2E2E;
        margin: 20px 0;
      }

      .guanli-card {
        display: flex;
        ;
        justify-content: center;
        align-items: center;
        height: 400x;

        .card {
          display: flex;
          ;
          flex-direction: column;
          border-radius: 6px;
          overflow: hidden;
          height: 240px;
          width: 240px;
          margin-right: 40px;
          box-shadow: 0px 3px 30px rgba(185, 199, 223, 0.25);
          font-size: 16px;
          padding: 14px 3px 14px 14px;

          .card-title {
            display: flex;
            ;
            align-items: center;
            font-size: 20px;
            font-weight: 500;

            span {
              color: #2E2E2E;
            }
          }

          .card-info {
            border-top: 1px solid #ebebeb;
            padding: 20px 10px 0 0;
            margin-top: 20px;
            text-align: left;
            line-height: 24px;
            height: 146px;
            font-size: 14px;
            color: #707070;
            overflow: auto;
          }

          .card-info::-webkit-scrollbar {
            position: relative;
            width: 7px;
            background-color: transparent;
            height: 10px;
          }

          .card-info::-webkit-scrollbar-thumb {
            background-color: #EAEEF2;
            border-radius: 7px;
          }

          .card-info::-webkit-scrollbar-thumb:hover {
            background-color: #EAEEF2;
          }

          img {
            margin-right: 10px;
            width: 40px;
            height: 40px;
          }
        }

        :nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }

    .gongneng {
      height: 720px;
      background-image: url("../../../public/img/oa/OA3.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      h1 {
        font-size: 36px;
        font-weight: 500;
        margin: 44px 0 20px 0;
        color: #fff;
      }

      ._main {
        display: none;
      }

      .main {
        height: 600px;
        width: 1200px;
        position: relative;
        display: flex;
        background-color: #fff;
        justify-content: center;
        align-items: center;

        .left {
          height: 100%;
          width: 260px;
          background-image: url("../../../public/img/oa/OA3-bg-2.png");
          background-repeat: no-repeat;
          background-size: cover;
          text-align: left;
          padding: 30px;
          box-sizing: border-box;

          p {
            color: #fff;
            margin-bottom: 16px;
          }

          :nth-child(1) {
            font-size: 24px;
            font-weight: 400;
          }

          :nth-child(2) {
            font-size: 24px;
            font-weight: 600;
          }

          :nth-child(3) {
            font-size: 16px;
            line-height: 30px;
            font-weight: 300;
            opacity: 0.86
          }

        }

        .right {
          height: 100%;
          background-color: #302e2e;
          width: 940px;

          .row {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .info {
              width: 235px;
              height: 200px;
              background: linear-gradient(to bottom right, #1E2025, #292c33);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              img {
                width: 60px;
                height: 60px;
                object-fit: cover;
              }

              span {
                color: #fff;
                margin-top: 20px;
                font-size: 16px;
              }
            }

            .info:hover {
              background: linear-gradient(to bottom right, #25272D, #313641);
            }
          }
        }
      }
    }

    .youshi {
      height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;

      .title {
        height: 60px;
        line-height: 60px;
        font-size: 36px;
        margin: 44px 0 20px 0;
      }
      ._youshi-card{
        display: none;
      }

      .youshi-card {
        display: flex;
        ;
        justify-content: center;
        align-items: center;
        height: 400x;
        width: 1200px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 5px 5px rgba(224, 224, 224, 0.2);

        .card {
          display: flex;
          ;
          flex-direction: column;
          // align-items: center;
          overflow: hidden;
          height: 300px;
          width: 300px;
          font-size: 16px;
          // padding: 14px;

          .card-title {
            // padding: 0 20px;
            padding-left: 24px;
            display: flex;
            ;
            align-items: center;
            background-color: #eaf2fc;
            justify-content: space-between;
            font-size: 20px;
            height: 160px;
            font-weight: 500;
          }

          .card-info {
            padding: 20px;
            text-align: left;
            line-height: 30px;
            font-size: 14px;
            height: 100%;
            border: 1px solid #f3f3f3;

            p {
              margin: 10px;
              color: #6783A1;

              img {
                vertical-align: middle;
                margin-right: 8px;
                width: 18px;
                height: 18px;
              }
            }
          }

          img {
            height: 94px;
            widows: 94px;
            margin-left: 10px;
          }
        }

        .card:hover {
          .card-title {
            background: linear-gradient(93deg, #0070EF 0%, #20A5FC 100%);
            color: #fff;
          }
        }
      }
    }

    .tongchou {
      height: 550px;
      background-image: url("../../../public/img/oa/OA5.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 1200px;
        display: flex;
        height: 466px;
        justify-content: space-between;
        align-items: center;
        color: #373737;
        text-align: left;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        padding: 20px;

        .info-text {
          p {
            color: #fff;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            margin: 20px;
          }

          .p1 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 500;
            letter-spacing: 3px;
          }

          .p2 {
            font-size: 14px;

          }

          .line {
            width: 39px;
            height: 6px;
            background: #FFFFFF;
            opacity: 1;
            margin: 20px;
            border-radius: 0px;
          }

          .button {
            width: 160px;
            height: 40px;
            background-color: #ffb83d;
            color: #fff;
            font-size: 14px;
            border: none;
            border-radius: 20px;
            position: relative;
          }
        }

        .info-img {
          width: 404px;
          height: 647px;
        }
      }
    }
    ._shouhou {
      display: none;
    }
    .shouhou {
      height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;

      .title {
        height: 60px;
        line-height: 60px;
        font-size: 36px;
        margin: 40px 0 20px 0;
      }

      .shouhou-card {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        div {
          width: 400px;
          height: 180px;
          background-color: #F5F9FF;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            z-index: 10;
            object-fit: cover;
          }

          img:hover {
            scale: 1.02;
            transition: 0.3s;
          }
        }

        .text {
          padding: 20px;
          box-sizing: border-box;

          h3 {
            color: #2E2E2E;
            font-size: 20px;
            font-weight: 600;
          }

          p {
            font-size: 14px;
            color: #707070;
            margin-top: 20px;
            line-height: 30px;
            width: 290px;
          }
        }
      }
    }

    .shiyong {
      height: 300px;
      background-image: url("../../../public/img/oa/OA6.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;

      h1 {
        margin-top: 20px;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 26px;
      }

      h3 {
        font-size: 20px;
        letter-spacing: 6px;
        margin-bottom: 26px;
        font-weight: 400;
      }

      .button {
        width: 158px;
        height: 50px;
        color: #3564FE;
        font-size: 20px;
        border-radius: 0;
      }

      img {
        width: 100px;
      }
    }
  }

}

// 小于1200px
@media only screen and (max-width: 1200px) {
  .homecontainer {
    font-size: 1vw;
    max-width: 100%;
    overflow: hidden;

    .main-top-banner {
      background-image: url("../../../public/img/home/home1-m.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        padding: 4vw;
        padding-top: 20vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .lvhuadai {
          text-align: center;

          p {
            font-size: 1vw;
            font-weight: 400;
            margin-bottom: 1vw;
          }

          .p1 {
            font-size: 6vw;
            font-weight: 600;
            color: #242526;
          }

          .p2 {
            font-size: 4vw;
            color: #7F90A7;
          }

          .button {
            margin: 2vw;
            font-size: 14px;
            color: #fff;
            height: 40px;
            width: 160px;
            border-radius: 4px;
            letter-spacing: 1.1px;
            text-align: center;
            background-color: #397ff9;
          }

          .button:hover {
            background-color: #71A5FF;
          }
        }
      }


      .img {
        width: 80vw;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .guanli {
      // height: 810px;
      background-image: url("../../../public/img/oa/OA2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 2vw;


      .center {
        display: none;
      }

      ._center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // padding: 2vw;

        .title {
          font-size: 6vw;
          color: #2E2E2E;
          margin: 20px 0;
        }

        .guanli-card {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 400x;
          margin-bottom: 2vw;

          .card {
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            overflow: hidden;
            height: 50vw;
            width: 42vw;
            margin-right: 2vw;
            box-shadow: 0px 3px 30px rgba(185, 199, 223, 0.25);
            font-size: 1vw;
            // padding: 14px 3px 14px 14px;
            padding: 2vw;

            .card-title {
              display: flex;
              align-items: center;
              font-size: 4vw;
              margin-top: 2vw;
              font-weight: 500;

              span {
                color: #2E2E2E;
              }
            }

            .card-info {
              border-top: 1px solid #ebebeb;
              padding: 2vw 1vw 0 0;
              margin-top: 4vw;
              text-align: left;
              line-height: 24px;
              height: 146px;
              font-size: 14px;
              color: #707070;
              overflow: auto;
            }

            .card-info::-webkit-scrollbar {
              position: relative;
              width: 7px;
              background-color: transparent;
              height: 10px;
            }

            .card-info::-webkit-scrollbar-thumb {
              background-color: #EAEEF2;
              border-radius: 7px;
            }

            .card-info::-webkit-scrollbar-thumb:hover {
              background-color: #EAEEF2;
            }

            img {
              margin-right: 2vw;
              width: 5vw;
              height: 5vw;
            }
          }

          :nth-last-child(1) {
            margin-right: 0px;
          }
        }

        .last {
          margin-bottom: 6vw;
          .card {
            // background-color: #ffb83d;
            width: 92vw;
            height: 40vw;
            margin-right: 0vw;
          }
        }
      }

    }

    .gongneng {
      // height: 720px;
      background-image: url("../../../public/img/oa/OA3.png");
      background-repeat: no-repeat;
      background-size: cover;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 2vw;

      h1 {
        font-size: 6vw;
        font-weight: 500;
        margin: 6vw 0 4vw 0;
        color: #fff;
      }

      .main {
        display: none;
      }

      ._main {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 4vw;

          .left {
            width: 43vw;
            height: 270px;
            height: 72vw;
            background-image: url("../../../public/img/oa/OA3-bg-2.png");
            background-repeat: no-repeat;
            background-size: cover;
            text-align: left;
            padding: 4vw 2vw 2vw 2vw;
            box-sizing: border-box;

            p {
              color: #fff;
              margin-bottom: 2vw;
            }

            :nth-child(1) {
              font-size: 5vw;
              font-weight: 400;
            }

            :nth-child(2) {
              font-size: 5vw;
              font-weight: 600;
            }

            :nth-child(3) {
              height: 46vw;
              font-size: 3.5vw;
              line-height: 6vw;
              font-weight: 300;
              opacity: 0.86;
              overflow: auto;
            }


            :nth-child(3)::-webkit-scrollbar {
              width: 3px;
              background-color: transparent;
            }

            :nth-child(3)::-webkit-scrollbar-thumb {
              background-color: #ffffff5d;
              border-radius: 4px;
            }

            :nth-child(3)::-webkit-scrollbar-thumb:hover {
              background-color: #EAEEF2;
            }
          }

          .right {
            background-color: #302e2e;
            width: 53vw;

            .row {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .info {
                width: 235px;
                height: 24vw;
                background: linear-gradient(to bottom right, #1E2025, #292c33);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                  width: 8vw;
                  height: 8vw;
                  object-fit: cover;
                }

                span {
                  color: #fff;
                  margin-top: 4vw;
                  font-size: 3.5vw;
                }
              }

              .info:hover {
                background: linear-gradient(to bottom right, #25272D, #313641);
              }
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 4vw;

          .right {
            width: 43vw;
            height: 270px;
            height: 72vw;
            background-image: url("../../../public/img/oa/OA3-bg-2.png");
            background-repeat: no-repeat;
            background-size: cover;
            text-align: left;
            padding: 4vw 2vw 2vw 2vw;
            box-sizing: border-box;

            p {
              color: #fff;
              margin-bottom: 2vw;
            }

            :nth-child(1) {
              font-size: 5vw;
              font-weight: 400;
            }

            :nth-child(2) {
              font-size: 5vw;
              font-weight: 600;
            }

            :nth-child(3) {
              height: 46vw;
              font-size: 3.5vw;
              line-height: 6vw;
              font-weight: 300;
              opacity: 0.86;
              overflow: auto;
            }


            :nth-child(3)::-webkit-scrollbar {
              width: 3px;
              background-color: transparent;
            }

            :nth-child(3)::-webkit-scrollbar-thumb {
              background-color: #ffffff5d;
              border-radius: 4px;
            }

            :nth-child(3)::-webkit-scrollbar-thumb:hover {
              background-color: #EAEEF2;
            }
          }

          .left {
            background-color: #302e2e;
            width: 53vw;

            .row {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .info {
                width: 235px;
                height: 24vw;
                background: linear-gradient(to bottom right, #1E2025, #292c33);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                  width: 8vw;
                  height: 8vw;
                  object-fit: cover;
                }

                span {
                  color: #fff;
                  margin-top: 4vw;
                  font-size: 3.5vw;
                }
              }

              .info:hover {
                background: linear-gradient(to bottom right, #25272D, #313641);
              }
            }
          }
        }



      }
    }

    .youshi {
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      display: flex;
      flex-direction: column;

      .title {
        height: 12vw;
        line-height: 12vw;
        color: #2E2E2E;
        font-size: 6vw;
        margin: 6vw 0 2vw 0;
      }

      .youshi-card {
        display: none;
      }

      ._youshi-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .card-list{
          display: flex;
          justify-content: center;
          margin-bottom: 4vw;

          :nth-last-child(1){
            margin-right: 0;
          }
        }
        
        .card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 47vw;
          font-size: 3vw;
          margin-right: 2vw;

          box-shadow: 0px 3px 15px rgba(185,199,223,0.25);

          .card-title {
            padding: 1vw 1vw 1vw 2vw;
            display: flex;
            align-items: center;
            background-color: #eaf2fc;
            justify-content: space-between;
            font-size: 4vw;
            font-weight: 500;
          }

          .card-info {
            padding: 1vw;
            text-align: left;
            line-height: 30px;
            font-size: 3vw;
            border: 1px solid #f3f3f3;
            color: #6783A1;

            p {
              margin: 1vw;
              img {
                vertical-align: middle;
                width: 2vw;
                height: 2vw;
                margin-right: 2vw;
              }
            }
          }

          img {
            height: 7vw;
            widows: 7vw;
            margin-left: 1vw;
          }
        }

        .card:hover {
          .card-title {
            background: linear-gradient(93deg, #0070EF 0%, #20A5FC 100%);
            color: #fff;
          }
        }
      }
    }

    .tongchou {
      background-image: url("../../../public/img/oa/OA5.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #373737;
        text-align: left;
        flex-direction: column;
        padding: 4vw;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: center;

        .info-text {
          p {
            color: #fff;
            line-height:6vw;
            text-align: left;
            margin: 2vw;
          }

          .p1 {
            font-size: 6vw;
            line-height: 7vw;
            font-weight: 500;
            letter-spacing: 3px;
          }

          .p2 {
            font-size: 3vw;
          }

          .line {
            width: 30px;
            height: 3px;
            background: #FFFFFF;
            opacity: 1;
            margin: 4vw 2vw;
            border-radius: 0px;
          }
        }

      }
      .info-img {
        width: 80vw;
        img{
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .shouhou{
      display: none;
    }

    ._shouhou {
      // height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;

      .title {
        line-height: 12vw;
        font-size: 6vw;
        margin: 6vw 0 2vw 0;
      }

      .shouhou-card {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          width: 48vw;
          height: 32vw;
          background-color: #F5F9FF;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            z-index: 10;
            object-fit: cover;
          }

          img:hover {
            scale: 1.02;
            transition: 0.3s;
          }
        }

        .text {
          box-sizing: border-box;
          padding: 2vw;

          h3 {
            color: #2E2E2E;
            font-size: 3.5vw;
            line-height: 7vw;
            font-weight: 600;
          }

          p {
            font-size: 3vw;
            color: #707070;
            line-height: 30px;
            height: 18vw;
            overflow: auto;
          }
        }
      }
    }

    .shiyong {
      background-image: url("../../../public/img/oa/OA6.png");
      background-repeat: no-repeat;
      background-size:cover;
      background-position: center;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;
      h1 {
        margin-top: 4vw;
        margin-bottom: 3vw;
        font-size: 6vw;
        font-weight: 400;
      }

      h3 {
        font-size: 3vw;
        letter-spacing:1.5px;
        margin-bottom: 3vw;
        font-weight: 300;
      }

      img {
        width: 80px;
        margin-bottom: 4vw;
      }
    }
  }

}</style>
